import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { debounce } from 'lodash';

import { Button, Input, Checkbox, Title } from 'jpi-cloud-web-ui-components';
import { InputDropdown } from '../../inputs/Dropdown';
import { address, phone, defaultMaxLength, yourTechnicianPattern } from '../../constants/constants';
import { formatErrorMessage } from '../../../localization/message-formatting';

import './registration-form.scss';

const registrationFormSchema = yup.object().shape({
  serialNumber: yup.string().trim().max(20, 'serial-number.maxlength').required('serial-number.required'),
  productName: yup.string().trim().max(defaultMaxLength, 'product-name.maxlength').required('product-name.required'),
  name: yup.string().trim().max(defaultMaxLength, 'name.maxlength').required('name.required'),
  email: yup.string().email('email.email').trim().required('email.required'),
  phone: yup
    .string()
    .trim()
    .min(phone.minLength, 'phone.minlength')
    .max(phone.maxLength, 'phone.maxlength')
    .matches(phone.regex, 'phone.regexp')
    .required('phone.required'),
  operatingHours: yup
    .number('operating-hours.number')
    .required('operating-hours.required')
    .min(0, 'operating-hours.minvalue'),
  installationDate: yup
    .string()
    .required('installation-date')
    .trim()
    .default(moment(new Date()).toString())
    .test('maxDate', 'installation-date.maxdate', function () {
      const dateRaw = this.resolve(yup.ref('installationDate'));
      return moment(dateRaw).isBefore(moment());
    })
    .test('minDate', 'installation-date.mindate', function () {
      const dateRaw = this.resolve(yup.ref('installationDate'));
      const fiveYearsAgo = moment().add(-5, 'years');
      return moment(dateRaw).isAfter(fiveYearsAgo);
    })
    .test('date-format', 'installation-date.date-format', function () {
      const dateRaw = this.resolve(yup.ref('installationDate'));
      return moment(dateRaw, 'YYYY-MM-DD', true).isValid();
    })
    .test('operating-hours-more-than-84', 'installation-date.operating-hours', function () {
      const op = this.resolve(yup.ref('operatingHours'));
      const id = moment(this.resolve(yup.ref('installationDate'))).startOf('day');
      const now = moment().startOf('day');
      if (op > 84 && now.isSame(id, 'day')) return false;
      return true;
    }),
  installerName: yup
    .string()
    .trim()
    .max(defaultMaxLength, 'installer-name.maxlength')
    .required('installer-name.required')
    .matches(yourTechnicianPattern.name.pattern, 'installer-name.invalid-format'),
  address1: yup
    .string()
    .trim()
    .max(defaultMaxLength, 'address.line-one.maxlength')
    .required('address.line-one.required')
    .matches(address.pattern, 'address.line-one.invalid-format'),
  address2: yup
    .string()
    .trim()
    .max(defaultMaxLength, 'address.line-two.maxlength')
    .matches(address.pattern, 'address.line-two.invalid-format')
    .nullable(),
  postalCode: yup
    .string()
    .trim()
    .max(address.postalCode.maxLength, 'postal-code.length')
    .required('postal-code.required')
    .matches(address.postalCode.regex, 'postal-code.regexp'),
  city: yup
    .string()
    .trim()
    .max(address.cityNameMaxLength, 'city.maxlength')
    .matches(address.pattern, 'city.invalid-format')
    .required('city.required'),
  region: yup
    .string()
    .trim()
    .max(address.regionNameMaxLength, 'region.maxlength')
    .matches(address.pattern, 'region.invalid-format')
    .nullable(),
  country: yup.object().nullable().required('country.required'),
  warrantyConditions: yup
    .boolean()
    .required('warranty-conditions.required')
    .oneOf([true], 'warranty-conditions.required'),
  dataConsent: yup.boolean().required('data-consent.required').oneOf([true], 'data-consent.required'),
});

const errorMessages = {
  'serial-number.maxlength': {
    id: 'serial-number.error.validation.maxlength',
    defaultMessage: 'Serial number cannot be longer than 20 characters',
  },
  'serial-number.required': {
    id: 'serial-number.error.validation.required',
    defaultMessage: 'Serial number is required',
  },
  'product-name.maxlength': {
    id: 'product-registration.error.validation.product-name.maxlength',
    defaultMessage: 'Product name cannot be longer than 255 characters',
  },
  'product-name.required': {
    id: 'product-registration.error.validation.product-name.required',
    defaultMessage: 'Product name is required',
  },
  'name.maxlength': {
    id: 'product-registration.error.validation.name.maxlength',
    defaultMessage: 'Name cannot be longer than 255 characters',
  },
  'name.required': {
    id: 'product-registration.error.validation.name.required',
    defaultMessage: 'Name is required',
  },
  'email.email': {
    id: 'email.error.validation.email',
    defaultMessage: 'E-Mail is not valid',
  },
  'email.required': {
    id: 'email.error.validation.required',
    defaultMessage: 'E-Mail is required',
  },
  'phone.minlength': {
    id: 'phone-number.error.validation.minlength',
    defaultMessage: 'Phone number cannot be shorter than 5 characters',
  },
  'phone.maxlength': {
    id: 'phone-number.error.validation.maxlength',
    defaultMessage: 'Phone number cannot be longer than 25 characters',
  },
  'phone.regexp': {
    id: 'phone-number.error.validation.regexp',
    defaultMessage: 'Phone number is not valid',
  },
  'phone.required': {
    id: 'phone-number.error.validation.required',
    defaultMessage: 'Phone number is required',
  },
  'operating-hours.minvalue': {
    id: 'product-registration.error.validation.operating-hours.minvalue',
    defaultMessage: 'Operating hours value cannot be less than 0',
  },
  'operating-hours.required': {
    id: 'product-registration.error.validation.operating-hours.required',
    defaultMessage: 'Operating hours value is required',
  },
  'installation-date.required': {
    id: 'product-registration.error.validation.installation-date.required',
    defaultMessage: 'Installation date is required',
  },
  'installation-date.maxdate': {
    id: 'product-registration.error.validation.installation-date.maxdate',
    defaultMessage: 'Installation date cannot be later than today',
  },
  'installation-date.mindate': {
    id: 'product-registration.error.validation.installation-date.mindate',
    defaultMessage: 'Installation date cannot be earlier than 5 years ago',
  },
  'installation-date.date-format': {
    id: 'product-registration.error.validation.installation-date.date-format',
    defaultMessage: 'Installation date has invalid format',
  },
  'installation-date.operating-hours': {
    id: 'product-registration.error.validation.installation-date.operating-hours',
    defaultMessage: 'Installation date cannot be today if operating hours value is more than 84',
  },
  'installer-name.maxlength': {
    id: 'product-registration.error.validation.installer-name.maxlength',
    defaultMessage: 'Installer name cannot be longer than 255 characters',
  },
  'installer-name.required': {
    id: 'product-registration.error.validation.installer-name.required',
    defaultMessage: 'Installer name is required',
  },
  'installer-name.invalid-format': {
    id: 'service-partner.error.validation.name.invalid-format',
    defaultMessage: 'Installer name has invalid format',
  },
  'address.line-one.maxlength': {
    id: 'address.error.validation.line-one.maxlength',
    defaultMessage: 'Address line 1 cannot be longer than 255 characters',
  },
  'address.line-one.required': {
    id: 'address.error.validation.line-one.required',
    defaultMessage: 'Address line 1 is required',
  },
  'address.line-one.invalid-format': {
    id: 'address.error.validation.line-one.invalid-format',
    defaultMessage: 'Address line 1 has invalid format',
  },
  'address.line-two.maxlength': {
    id: 'address.error.validation.line-two.maxlength',
    defaultMessage: 'Address line 2 cannot be longer than 255 characters',
  },
  'address.line-two.invalid-format': {
    id: 'address.error.validation.line-two.invalid-format',
    defaultMessage: 'Address line 2 has invalid format',
  },
  'postal-code.maxlength': {
    id: 'postal-code.error.validation.maxlength',
    defaultMessage: 'Postal code cannot be longer than 20 characters',
  },
  'postal-code.required': {
    id: 'postal-code.error.validation.required',
    defaultMessage: 'Postal code is mandatory field',
  },
  'postal-code.regexp': {
    id: 'postal-code.error.validation.regexp',
    defaultMessage: 'Postal code has an invalid format',
  },
  'city.required': {
    id: 'city.error.validation.required',
    defaultMessage: 'City is mandatory field',
  },
  'city.maxlength': {
    id: 'city.error.validation.maxlength',
    defaultMessage: 'City cannot be longer than 100 characters',
  },
  'city.invalid-format': {
    id: 'city.error.validation.invalid-format',
    defaultMessage: 'City has invalid format',
  },
  'region.maxlength': {
    id: 'region.error.validation.maxlength',
    defaultMessage: 'Region cannot be longer than 100 characters',
  },
  'region.invalid-format': {
    id: 'region.error.validation.invalid-format',
    defaultMessage: 'Region has invalid format',
  },
  'country.required': {
    id: 'country.error.validation.required',
    defaultMessage: 'City is mandatory field',
  },
  'warranty-conditions.required': {
    id: 'product-registration.error.validation.warranty-conditions.required',
    defaultMessage: 'Must accept Warranty Conditions',
  },
  'data-consent.required': {
    id: 'product-registration.error.validation.data-consent.required',
    defaultMessage: 'Must accept Data Consent',
  },
};

const RegisterProductFormInner = ({
  initialValues,
  countries,
  onSubmit,
  goToPage,
  toggleWarrantyInfoModal,
  intl,
  yourTechnician,
  getYourTechnicianByNameHint,
  yourTechnicianResult,
}) => {
  const getYourTechnicianByName = debounce(async (value) => {
    const data = await getYourTechnicianByNameHint(value);
    return data;
  }, 300);

  return (
    <Formik
      initialValues={initialValues}
      countries={countries}
      validationSchema={registrationFormSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="product-registration__form">
          <h3>
            <FormattedMessage id="productRegistration.subtitle.1" defaultMessage="Product Information" />
          </h3>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.serialNumber" defaultMessage="Serial Number">
              {(placeholder) => (
                <Input
                  name="serialNumber"
                  type="text"
                  placeholder={placeholder}
                  value={values.serialNumber || ''}
                  error={errors.serialNumber ? formatErrorMessage(intl, errorMessages, errors.serialNumber) : ''}
                  onChange={handleChange}
                  readOnly={true}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.productName" defaultMessage="Product Name">
              {(placeholder) => (
                <Input
                  name="productName"
                  type="text"
                  placeholder={placeholder}
                  value={values.productName || ''}
                  error={errors.productName ? formatErrorMessage(intl, errorMessages, errors.productName) : ''}
                  onChange={handleChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.name" defaultMessage="Name">
              {(placeholder) => (
                <Input
                  name="name"
                  type="text"
                  placeholder={placeholder}
                  value={values.name || ''}
                  error={errors.name ? formatErrorMessage(intl, errorMessages, errors.name) : ''}
                  onChange={handleChange}
                  readOnly={true}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.email" defaultMessage="E-mail">
              {(placeholder) => (
                <Input
                  name="email"
                  type="text"
                  placeholder={placeholder}
                  value={values.email || ''}
                  error={errors.email ? formatErrorMessage(intl, errorMessages, errors.email) : ''}
                  onChange={handleChange}
                  readOnly={true}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.phone" defaultMessage="Telephone">
              {(placeholder) => (
                <Input
                  name="phone"
                  type="text"
                  placeholder={placeholder}
                  value={values.phone || ''}
                  error={errors.phone ? formatErrorMessage(intl, errorMessages, errors.phone) : ''}
                  onChange={handleChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.operatingHours" defaultMessage="Operating Hours">
              {(placeholder) => (
                <Input
                  name="operatingHours"
                  type="text"
                  placeholder={placeholder}
                  value={values.operatingHours || ''}
                  error={errors.operatingHours ? formatErrorMessage(intl, errorMessages, errors.operatingHours) : ''}
                  onChange={handleChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.installationDate" defaultMessage="Installation Date (YYYY-MM-DD)">
              {(placeholder) => (
                <Input
                  name="installationDate"
                  type="text"
                  placeholder={placeholder}
                  value={values.installationDate || ''}
                  error={
                    errors.installationDate ? formatErrorMessage(intl, errorMessages, errors.installationDate) : ''
                  }
                  onChange={handleChange}
                />
              )}
            </FormattedMessage>
          </div>
          {/*
              // DatePicker is mostly working, except it doesn't close when you select the date. Need to click outside to close it.
              // Leaving this commented out for now, so we can get back to it in the future.
              //
              <label>
                <span className="fa-stack" id="installation-date-icon">
                  <i className="fa fa-stack-2x fa-calendar-alt"></i>
                </span>
                <DatePicker shouldCloseOnSelect={true}
                  name={'installationDate'} value={values['installationDate']}
                  onChange={e => setFieldValue('installationDate', e.toISOString().substring(0, 10)) } />
              </label>
            */}
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage
              id="productRegistration.input.installerName"
              defaultMessage="Start typing installer name..."
            >
              {(placeholder) => (
                <InputDropdown
                  name="installerName"
                  type="text"
                  id={Date.now().toString(36) + Math.random().toString(36).slice(2) + 'installerName'}
                  placeholder={placeholder}
                  error={errors.installerName ? formatErrorMessage(intl, errorMessages, errors.installerName) : ''}
                  selectedItem={values.installerName}
                  items={
                    yourTechnicianResult.length > 0 || values.installerName ? yourTechnicianResult : yourTechnician
                  }
                  labelGetter={(s) => s.servicePartnerName}
                  onSelect={(yourTechnician) => {
                    if (typeof yourTechnician !== 'string') {
                      setFieldValue('installerName', yourTechnician.servicePartnerName);
                    }
                  }}
                  onChange={async (e) => {
                    setFieldValue('installerName', e.target.value);
                    const match = e.target.value.match(yourTechnicianPattern.name.pattern);
                    if (match && match.length > 0) {
                      await getYourTechnicianByName(e.target.value);
                    }
                  }}
                />
              )}
            </FormattedMessage>
          </div>
          <h3 className="col-sm-12">
            <FormattedMessage id="productRegistration.subtitle.2" defaultMessage="Product Location" />
          </h3>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.address1" defaultMessage="Address Line 1">
              {(placeholder) => (
                <Input
                  name="address1"
                  type="text"
                  placeholder={placeholder}
                  value={values.address1 || ''}
                  error={errors.address1 ? formatErrorMessage(intl, errorMessages, errors.address1) : ''}
                  onChange={handleChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.address2" defaultMessage="Address Line 2">
              {(placeholder) => (
                <Input
                  name="address2"
                  type="text"
                  placeholder={placeholder}
                  value={values.address2 || ''}
                  error={errors.address2 ? formatErrorMessage(intl, errorMessages, errors.address2) : ''}
                  onChange={handleChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.postalCode" defaultMessage="Zip Code \/ Postal Code">
              {(placeholder) => (
                <Input
                  name="postalCode"
                  type="text"
                  placeholder={placeholder}
                  value={values.postalCode || ''}
                  error={errors.postalCode ? formatErrorMessage(intl, errorMessages, errors.postalCode) : ''}
                  onChange={handleChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.city" defaultMessage="City">
              {(placeholder) => (
                <Input
                  name="city"
                  type="text"
                  placeholder={placeholder}
                  value={values.city || ''}
                  error={errors.city ? formatErrorMessage(intl, errorMessages, errors.city) : ''}
                  onChange={handleChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.region" defaultMessage="State \/ Province \/ Region">
              {(placeholder) => (
                <Input
                  name="region"
                  type="text"
                  placeholder={placeholder}
                  value={values.region || ''}
                  error={errors.region ? formatErrorMessage(intl, errorMessages, errors.region) : ''}
                  onChange={handleChange}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-6 registration-form-input">
            <FormattedMessage id="productRegistration.country" defaultMessage="Country">
              {(placeholder) => (
                <InputDropdown
                  placeholder={placeholder}
                  isReadOnly
                  selectedItem={values.country || null}
                  id={Date.now().toString(36) + Math.random().toString(36).slice(2) + 'country'}
                  name="country"
                  items={countries}
                  error={errors.country ? formatErrorMessage(intl, errorMessages, errors.country) : ''}
                  labelGetter={(e) => e.name}
                  onSelect={(c) => {
                    if (typeof c !== 'string') {
                      setFieldValue('country', c);
                      return;
                    }

                    const matchedCountries =
                      (c &&
                        countries.filter((country) => {
                          return country.name.toLowerCase() === c.toLowerCase().trim();
                        })) ||
                      [];
                    matchedCountries.length > 0
                      ? setFieldValue('country', matchedCountries.pop())
                      : setFieldValue('country', null);
                  }}
                  itemFilter={(country, input) => {
                    return !input || country.name.toLowerCase().startsWith(input.toLowerCase().trim());
                  }}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-12">
            <FormattedMessage
              id="productRegistration.warrantyConditions"
              defaultMessage="I hereby declare that I am the owner of the product and that I've read and understood the warranty conditions"
            >
              {(title) => (
                <Checkbox
                  id="warrantyConditions"
                  name="warrantyConditions"
                  className="underline"
                  checked={values.warrantyConditions}
                  onChange={handleChange}
                  label={title}
                  onLabelClick={() => toggleWarrantyInfoModal('productRegistration.warrantyConditions.text')}
                  error={
                    errors.warrantyConditions ? formatErrorMessage(intl, errorMessages, errors.warrantyConditions) : ''
                  }
                />
              )}
            </FormattedMessage>
          </div>
          <div className="col-sm-12">
            <FormattedMessage
              id="productRegistration.dataConsent"
              defaultMessage="I hereby consent to share my system data with myUpTech and all its affiliated companies"
            >
              {(title) => (
                <Checkbox
                  id="dataConsent"
                  name="dataConsent"
                  className="underline"
                  checked={values.dataConsent}
                  onChange={handleChange}
                  label={title}
                  onLabelClick={() => toggleWarrantyInfoModal('privacy-policy')}
                  error={errors.dataConsent ? formatErrorMessage(intl, errorMessages, errors.dataConsent) : ''}
                />
              )}
            </FormattedMessage>
          </div>
          <div className="button-wrapper col-sm-12">
            <Button className="button--default" type="button" onClick={() => goToPage('/')}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>

            <Button className="button--secondary" type="submit" disabled={isSubmitting}>
              <FormattedMessage id="productRegistration.register" defaultMessage="Register" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

RegisterProductFormInner.propTypes = {
  initialValues: PropTypes.object,
  countries: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  goToPage: PropTypes.func.isRequired,
  toggleWarrantyInfoModal: PropTypes.func.isRequired,
  intl: PropTypes.object,
  yourTechnician: PropTypes.array.isRequired,
  getYourTechnicianByNameHint: PropTypes.func.isRequired,
  yourTechnicianResult: PropTypes.array.isRequired,
};

const RegisterProductForm = injectIntl(RegisterProductFormInner);

export const RegistrationForm = ({
  onSubmit,
  countries,
  initialValues,
  goToPage,
  toggleWarrantyInfoModal,
  yourTechnician,
  getYourTechnicianByNameHint,
  yourTechnicianResult,
}) => (
  <div className="product-registration step-2">
    <div className="product-registration__wrapper">
      <Title titleTranslationId="productRegistration.subtitle" defaultMessage="Register Your Product" />
    </div>
    <RegisterProductForm
      onSubmit={onSubmit}
      countries={countries}
      initialValues={initialValues}
      goToPage={goToPage}
      toggleWarrantyInfoModal={toggleWarrantyInfoModal}
      yourTechnician={yourTechnician}
      getYourTechnicianByNameHint={getYourTechnicianByNameHint}
      yourTechnicianResult={yourTechnicianResult}
    />
  </div>
);

RegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.object),
  initialValues: PropTypes.object,
  goToPage: PropTypes.func.isRequired,
  toggleWarrantyInfoModal: PropTypes.func.isRequired,
  yourTechnician: PropTypes.array.isRequired,
  getYourTechnicianByNameHint: PropTypes.func.isRequired,
  yourTechnicianResult: PropTypes.array.isRequired,
};
