import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Spinner, Title } from 'jpi-cloud-web-ui-components';

import AddressTab from './components/AddressTab/AddressTab';
import DeleteTab from './components/DeleteTab/DeleteTab';
import ProfileTab from './components/ProfileTab/ProfileTab';
import SecurityTab from './components/SecurityTab/SecurityTab';
import YourTechnicianTab from './components/YourTechnicianTab/YourTechnicianTab';
import AboutManufacturerTab from './components/AboutManufacturerTab/AboutManufacturerTab';

import { getYourTechnicianInfo } from './components/YourTechnicianTab/actions';

import { USER_SECURITY_LEVELS } from './components/SecurityTab/costants';
import './systemProfilePage.scss';

let timeout;

const SystemProfilePage = ({
  selectedSystem,
  selectedSystemBrandId,
  yourTechnician,
  history,
  getYourTechnicianInfo,
}) => {
  const [spLoaded, setSPLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState('profile');

  const loadYourTechnician = async () => {
    await getYourTechnicianInfo(selectedSystem.id);

    setSPLoaded(true);
  };

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash.substring(1);
      const newUrlIS = window.location.origin + '/system-profile';
      if (hash === 'service-partner') {
        setSelectedTab(hash);
        window.history.pushState('data', null, newUrlIS);
      }
    }
    setIsLoaded(true);

    loadYourTechnician();

    return () => clearTimeout(timeout);
  }, [selectedSystem, window.location.href]);

  useEffect(() => {
    if (selectedSystemBrandId !== 'CLIMATEMASTER' && selectedTab === 'about-manufacturer') {
      setSelectedTab('profile');
    }
  });

  const selectTab = (ev, tab) => {
    const divElement = document.getElementById(ev.currentTarget.id);
    if (divElement) {
      divElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
    timeout = setTimeout(() => {
      setSelectedTab(tab);
    }, '200');
  };

  const getCurrentTabContent = () => {
    const userIsAdmin = selectedSystem.userRole === USER_SECURITY_LEVELS.admin;

    switch (selectedTab) {
      case 'profile':
        return <ProfileTab selectedSystem={selectedSystem} />;
      case 'security':
        return <SecurityTab selectedSystem={selectedSystem} userIsAdmin={userIsAdmin} />;
      case 'address':
        return <AddressTab selectedSystem={selectedSystem} userIsAdmin={userIsAdmin} />;
      case 'service-partner':
        return yourTechnician?.name ? <YourTechnicianTab /> : setSelectedTab('profile');
      case 'about-manufacturer':
        return <AboutManufacturerTab />;
      case 'delete':
        return <DeleteTab selectedSystem={selectedSystem} userIsAdmin={userIsAdmin} history={history} />;
      default:
        return null;
    }
  };

  return (
    <>
      {isLoaded ? (
        <div className="page-content">
          <div className="system-profile-page">
            <div className="tabordion">
              <section className="profile-section" id="profile-section">
                <button
                  id="profile"
                  onClick={(ev) => selectTab(ev, 'profile')}
                  className={selectedTab === 'profile' ? 'option-label selected' : 'option-label'}
                >
                  <FormattedMessage id={'label.system-profile.profile'} defaultMessage="Profile" />
                </button>
                <button
                  id="security"
                  onClick={(ev) => selectTab(ev, 'security')}
                  className={selectedTab === 'security' ? 'option-label selected' : 'option-label'}
                >
                  <FormattedMessage id={'label.system-profile.security'} defaultMessage="Security" />
                </button>
                <button
                  id="address"
                  onClick={(ev) => selectTab(ev, 'address')}
                  className={selectedTab === 'address' ? 'option-label selected' : 'option-label'}
                >
                  <FormattedMessage id={'label.system-profile.address'} defaultMessage="Address" />
                </button>
                {spLoaded && yourTechnician?.name && (
                  <button
                    id="service-partner"
                    onClick={(ev) => selectTab(ev, 'service-partner')}
                    className={selectedTab === 'service-partner' ? 'option-label selected' : 'option-label'}
                  >
                    <FormattedMessage id={'label.system-profile.your-technician'} defaultMessage="Your Technician" />
                  </button>
                )}
                {selectedSystemBrandId === 'CLIMATEMASTER' && (
                  <button
                    id="about-manufacturer"
                    onClick={(ev) => selectTab(ev, 'about-manufacturer')}
                    className={selectedTab === 'about-manufacturer' ? 'option-label selected' : 'option-label'}
                  >
                    <FormattedMessage
                      id={'label.system-profile.about-manufacturer'}
                      defaultMessage="About Manufacturer"
                    />
                  </button>
                )}
                <button
                  id="delete"
                  onClick={(ev) => selectTab(ev, 'delete')}
                  className={selectedTab === 'delete' ? 'option-label selected' : 'option-label'}
                >
                  <FormattedMessage id={'label.system-profile.delete'} defaultMessage="Delete" />
                </button>
              </section>
              <div className="tab-content">
                <Title titleTranslationId="system-profile.title" defaultMessage="System Profile" />
                {spLoaded && getCurrentTabContent()}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner dark />
      )}
    </>
  );
};

SystemProfilePage.propTypes = {
  profilePage: PropTypes.object,
  selectedSystem: PropTypes.object,
  selectedSystemBrandId: PropTypes.string,
  history: PropTypes.object.isRequired,
  yourTechnician: PropTypes.object,
  userInfo: PropTypes.object,
};

export default connect(
  ({ app, profilePage, yourTechnician: { yourTechnician } }) => ({
    userInfo: app.userInfo,
    selectedSystem: app.selectedSystem ? app.selectedSystem : { name: null },
    selectedSystemBrandId: app.selectedSystem ? app.selectedSystem.brandId : null,
    yourTechnician,
    profilePage,
  }),
  {
    getYourTechnicianInfo,
  }
)(SystemProfilePage);
