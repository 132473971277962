import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { systemRoles, USER_STATUS, USER_SECURITY_LEVELS, ConnectHP24AccountStatus } from './costants';

import { Spinner } from 'jpi-cloud-web-ui-components';

import AddUserToSystemForm from './components/AddUserToSystemForm';
import DisconnectUserConfirmation from './components/DisconnectUserConfirmationModal';
import UsersList from './components/UsersList';

import { connectUser, disconnectUser, changeUserSecurityLevel, getSystemUsers, getSystemRolesAction } from './actions';
import { getYourTechnicianInfo } from '../YourTechnicianTab/actions';

import './security.scss';

const notificationTimeoutMilliseconds = 30000;

class SecurityTab extends React.Component {
  state = {
    showModal: false,
    selectedUser: null,
  };

  async componentDidMount() {
    await this.fetchUsers(this.props.selectedSystemId);
    await this.props.getSystemRolesAction(this.props.selectedSystemId);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.selectedSystemId !== prevProps.selectedSystemId) {
      await this.fetchUsers(this.props.selectedSystemId);
      await this.props.getSystemRolesAction(this.props.selectedSystemId);
    }
  }

  async fetchUsers(systemId) {
    if (systemId === null) return;

    await this.props.getSystemUsers(systemId);
  }

  disconnectUser = async () => {
    const { disconnectUser, getYourTechnicianInfo } = this.props;

    await disconnectUser(this.props.selectedSystemId, this.state.selectedUser);

    this.toggleModal(null);

    // BUG: DO NOT destructure
    if (!this.props.userRemoved)
      return NotificationManager.error(
        <FormattedMessage id="security.DeleteUserError" defaultMessage="User could not be removed" />,
        <FormattedMessage id="security.Error" defaultMessage="Error" />,
        notificationTimeoutMilliseconds
      );

    await getYourTechnicianInfo(this.props.selectedSystemId);
  };

  onSubmit = async (values, { resetForm }) => {
    await this.props.connectUser(this.props.selectedSystemId, values.email.trim(), values.securityLevel.value);
    const isHP24Installer = values.securityLevel.value === USER_SECURITY_LEVELS.hp24installer;

    // BUG: DO NOT destructure
    if (this.props.userAdded) {
      await this.props.getSystemUsers(this.props.selectedSystemId);
      await getYourTechnicianInfo(this.props.selectedSystemId);
      resetForm();

      let successMessage;
      if (isHP24Installer) {
        successMessage =
          this.props.userConnectInfo === ConnectHP24AccountStatus.UserInvited ? (
            <FormattedMessage
              id="security.HP24ConnectSuccessUserInvited"
              defaultMessage="This heatpump24 account does not exist. We have sent an email for registration. After registration, the user will be added to the system."
            />
          ) : (
            <FormattedMessage
              id="security.HP24ConnectSuccessRequestConfirmation"
              defaultMessage="Heatpump24 account has been invited."
            />
          );
      } else {
        successMessage = (
          <FormattedMessage id="security.ConnectUserSuccess" defaultMessage="User connected successfully" />
        );
      }

      return NotificationManager.success(
        successMessage,
        <FormattedMessage id="security.Success" defaultMessage="Success" />,
        3000
      );
    }

    if (this.props.userConnectStatus === 409) {
      const errorMessage = isHP24Installer ? (
        <FormattedMessage
          id="security.HP24ConnectErrorOnConflict"
          defaultMessage="Heatpump24 account is already invited."
        />
      ) : (
        <FormattedMessage id="security.UserAlreadyConnected" defaultMessage="User is already invited" />
      );

      return NotificationManager.error(
        errorMessage,
        <FormattedMessage id="security.Error" defaultMessage="Error" />,
        3000
      );
    }

    if (isHP24Installer && this.props.userConnectStatus === 400) {
      return NotificationManager.error(
        <FormattedMessage
          id="security.HP24ConnectErrorOnBadRequest"
          defaultMessage="Invitation of heatpump24 installer was not possible. Please try again later."
        />,
        <FormattedMessage id="security.Error" defaultMessage="Error" />,
        3000
      );
    }

    return NotificationManager.error(
      <FormattedMessage
        id="security.ConnectUserError"
        defaultMessage="This user does not exist. We have sent an email for registration. After registration, the user will be added to the system."
      />,
      <FormattedMessage id="security.Error" defaultMessage="Error" />,
      3000
    );
  };

  changeUserSecurityLevel = async (user, securityLevel) => {
    if (user.role != securityLevel) {
      await this.props.changeUserSecurityLevel(this.props.selectedSystemId, user.id, securityLevel);

      // BUG: DO NOT destructure
      if (!this.props.userUpdated)
        NotificationManager.error(
          <FormattedMessage id="security.UpdateUserError" defaultMessage="User could not be updated" />,
          <FormattedMessage id="security.Error" defaultMessage="Error" />,
          notificationTimeoutMilliseconds
        );
    }
  };

  toggleModal = (user) => {
    this.setState({ ...this.state, showModal: !this.state.showModal, selectedUser: user || null });
  };

  render() {
    const { users, usersLoading, userConnectLoading, userUpdateLoading, userIsAdmin, userInfo, roles, rolesLoading } =
      this.props;
    const { showModal } = this.state;

    const isDemo = userInfo && userInfo.isDemo;

    const demoUser = [
      {
        name: userInfo.fullName,
        id: userInfo.id,
        role: userInfo.spRoles.join(' '),
        email: userInfo.email,
      },
    ];

    const connectedUsersList = isDemo ? demoUser : users.filter((user) => user.status === USER_STATUS.Approved);
    const invitedUsersList = isDemo ? demoUser : users.filter((user) => user.status === USER_STATUS.PendingApprove);
    const userRoles = systemRoles.filter((role) => roles?.includes(role.value));

    return (
      <div className="system-profile-security-section">
        {userIsAdmin && (
          <section className="connect-user-section">
            <h3>
              <FormattedMessage id="security.ConnectUser" defaultMessage="Connect a user" />
            </h3>
            <AddUserToSystemForm
              userRoles={userRoles}
              userRolesLoading={rolesLoading}
              onSubmit={this.onSubmit}
              userConnectLoading={userConnectLoading}
              isDemo={isDemo}
            />
            <hr />
          </section>
        )}

        <section className="user-management-section">
          <h3>
            <FormattedMessage id="security.ConnectedUsers" defaultMessage="Connected users" />
          </h3>
          {usersLoading ? (
            <Spinner />
          ) : (
            users && (
              <UsersList
                users={connectedUsersList}
                toggleModal={this.toggleModal}
                changeSecurityLevel={this.changeUserSecurityLevel}
                isUserUpdating={userUpdateLoading}
                userIsAdmin={userIsAdmin}
                email={userInfo.email}
                isDemo={isDemo}
              />
            )
          )}
          {!usersLoading && Boolean(invitedUsersList.length) && (
            <>
              <h3>
                <FormattedMessage id="security.InvitedUsers" defaultMessage="Invited users" />
              </h3>
              <UsersList
                users={invitedUsersList}
                toggleModal={this.toggleModal}
                changeSecurityLevel={this.changeUserSecurityLevel}
                isUserUpdating={userUpdateLoading}
                userIsAdmin={userIsAdmin}
                email={userInfo.email}
                isDemo={isDemo}
              />
            </>
          )}
        </section>
        <DisconnectUserConfirmation
          showModal={showModal}
          disconnecting={this.props.userRemoveLoading}
          disconnectUser={this.disconnectUser}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}

SecurityTab.propTypes = {
  selectedSystem: PropTypes.object.isRequired,
  history: PropTypes.object,
  userInfo: PropTypes.object,
  userIsAdmin: PropTypes.bool.isRequired,

  selectedSystemId: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
  usersLoading: PropTypes.bool,
  userConnectLoading: PropTypes.bool,
  userConnectInfo: PropTypes.string,
  userConnectStatus: PropTypes.number,
  userUpdateLoading: PropTypes.bool,
  userRemoveLoading: PropTypes.bool,
  userAdded: PropTypes.bool,
  userUpdated: PropTypes.bool,
  userRemoved: PropTypes.bool,
  requestError: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  rolesLoading: PropTypes.bool,

  connectUser: PropTypes.func.isRequired,
  disconnectUser: PropTypes.func.isRequired,
  changeUserSecurityLevel: PropTypes.func.isRequired,
  getYourTechnicianInfo: PropTypes.func.isRequired,
  getSystemUsers: PropTypes.func.isRequired,
  getSystemRolesAction: PropTypes.func.isRequired,
};

export default connect(
  ({ app: { selectedSystem, userInfo }, systemProfileSecurityTab }) => ({
    userInfo,
    selectedSystemId: selectedSystem ? selectedSystem.id : null,
    ...systemProfileSecurityTab,
  }),
  {
    connectUser,
    disconnectUser,
    changeUserSecurityLevel,
    getYourTechnicianInfo,
    getSystemUsers,
    getSystemRolesAction,
  }
)(SecurityTab);
