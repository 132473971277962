import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import { formatErrorMessage } from '../../../../../localization/message-formatting';
import { isAzureB2CAuth } from '../../../../../localStorage/authentication';
import { ENV } from '../../../../../api';

const schema = yup.object().shape({
  password: yup.string().required('password.required'),
});

const errorMessages = {
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  'password.incorrect': {
    id: 'password.error.request.invalid',
    defaultMessage: 'Password is invalid. Please try again.',
  },
};
const deleteServicePartnerUrl = `${process.env.WEB_PRO_URL}/service-partner-settings`;

const DeleteAccountForm = ({ onSubmit, handleSendConfirmationForDelete, requestError, intl }) => {
  const isAzureB2C = isAzureB2CAuth();

  return (
    <Formik initialValues={{ password: '' }} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="form--half">
          <p>
            <FormattedMessage
              id="label.system-profile.delete-account-warning"
              defaultMessage="Delete personal account"
            />
          </p>
          <Input
            placeholder={intl.formatMessage({ id: 'label.password' })}
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors.password && touched.password && formatErrorMessage(intl, errorMessages, errors.password)}
          />
          {requestError && (
            <p className="text-danger">
              {requestError === 'last.admin' ? (
                <FormattedMessage
                  id={'user.delete.error.request.last.admin'}
                  defaultMessage={`To delete your user account at myUplink, 
                you need to first delete your Service Partner account at {here}. 
                If you want to still use your account at myUplinkPro, please keep this user account at myUplink as well.`}
                  values={{
                    myUplinkPro: (
                      <u
                        className="email-not-confirmed-message"
                        onClick={() => window.open(deleteServicePartnerUrl, '_blank')}
                      >
                        myUplinkPro
                      </u>
                    ),
                  }}
                />
              ) : (
                formatErrorMessage(intl, errorMessages, requestError)
              )}
            </p>
          )}
          <div className="button-wrapper">
            <Button className="button--default" type="submit">
              <FormattedMessage id="button.delete" defaultMessage="Delete" />
            </Button>{' '}
            {isAzureB2C && ENV !== 'production' && (
              <>
                <div style={{ marginRight: '1.5rem', lineHeight: '3.25rem' }}>
                  <FormattedMessage id="register.or" defaultMessage="or" />
                </div>
                <button
                  className="link-text link-text-underline link-text-big"
                  onClick={handleSendConfirmationForDelete}
                  type="button"
                >
                  <FormattedMessage id="sendConfirmEmailLink.deleteButton" defaultMessage="Delete via email" />
                </button>
              </>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};
DeleteAccountForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleSendConfirmationForDelete: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(DeleteAccountForm);
