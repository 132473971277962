import {
  GET_INVITATION_REQUEST,
  GET_INVITATION_RESPONSE,
  GET_INVITATION_RESPONSE_FAILED,
  ACCEPT_INVITATION,
  DECLINE_INVITATION,
} from './actions';

import { LOGOUT_USER } from '../Login/actions';

const initialState = {
  loading: false,
  loaded: false,
  invitationId: '',
  yourTechnicianInfo: { name: '' },
  systems: {},
  invitation: {},
  valid: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVITATION_REQUEST:
      return { ...state, loading: true, loaded: false };
    case GET_INVITATION_RESPONSE:
      return {
        ...state,
        invitationId: action.invitation.id,
        yourTechnicianInfo: action.yourTechnicianInfo,
        systems: action.systems,
        invitation: action.invitation,
        valid: action.valid,
        loading: false,
        loaded: true,
      };
    case GET_INVITATION_RESPONSE_FAILED:
      return { ...state, loaded: true, loading: false, valid: false };
    case ACCEPT_INVITATION:
      return { ...state, invitation: action.invitation };
    case DECLINE_INVITATION:
      return { ...state, invitation: action.invitation };
    case LOGOUT_USER:
      return {
        ...state,
        invitationId: '',
        yourTechnicianInfo: {},
        systems: {},
        invitation: {},
        valid: null,
        loaded: false,
        loading: false,
      };
    default:
      return state;
  }
};
