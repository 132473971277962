// @ts-check
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalBody } from 'react-bootstrap';
import { Button, Spinner } from 'jpi-cloud-web-ui-components';
import Modal from '../../layout/Modal';
import { USER_SECURITY_LEVELS } from '../SystemProfile/components/SecurityTab/costants';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { getInvitation, acceptInvitation, declineInvitation } from './actions';
import SystemList from './SystemList';
// eslint-disable-next-line
import * as InvitationTypes from './invitation-types';

import './invitation.scss';
import { MAX_SYSTEMS_SELECTED } from './const';

var divStylePaddingTop = {
  paddingTop: '0.5rem',
};

/**
 * Invitation component.
 *
 * @param {InvitationTypes.InvitationComponent} props - Component props.
 * @returns {React.ReactNode} Rendered Invitation component.
 */
const Invitation = ({
  match,
  userInfo,
  invitation,
  systems,
  yourTechnicianInfo,
  valid,
  getInvitation,
  acceptInvitation,
  declineInvitation,
  loading,
  loaded,
}) => {
  // FIXME: systems in redux is initally an object, not an array
  const systemsWhereUserIsAdmin = useMemo(
    () =>
      Array.isArray(systems) ? systems?.filter((system) => system.userRole === USER_SECURITY_LEVELS.admin) ?? [] : [],
    [systems?.length]
  );

  const hasOnlyOneSystemAvailable = systemsWhereUserIsAdmin.length === 1;
  const maxNumberOfGroups = invitation.numberOfGroups ?? MAX_SYSTEMS_SELECTED;

  const [state, setState] = useState({
    invitationId: '',
    email: '',
    systemId: '',
    isSubmitting: false,
    showLegalInfoModal: false,
    legalInfoType: null,
    showError: false,
    selectedSystem: null,
  });

  /**
   * @type {[InvitationTypes.System[], React.Dispatch<React.SetStateAction<InvitationTypes.System[]>>]} selectedSystems
   */
  const [selectedSystems, setSelectedSystems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  /**
   * @type {[InvitationTypes.System[], React.Dispatch<InvitationTypes.System[]>]} FilteredSystemListState
   */
  const [filteredSystemList, setFilteredSystemList] = useState(systemsWhereUserIsAdmin);

  const allSelected =
    hasOnlyOneSystemAvailable ||
    filteredSystemList.every((system) => selectedSystems.some((selectedSystem) => selectedSystem.id === system.id));

  useEffect(() => {
    const filteredSystemListBySearchTerm = systemsWhereUserIsAdmin.filter((system) => {
      return (
        system.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        system.serialNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

    setFilteredSystemList(filteredSystemListBySearchTerm);
  }, [searchTerm, systemsWhereUserIsAdmin.length]);

  useEffect(() => {
    if (userInfo && userInfo.email && !loading && !loaded) {
      getInvitation(match.params.id, userInfo.email);
      setState({ ...state, email: userInfo.email });
    }
    if (
      invitation &&
      invitation.status &&
      (invitation.status.toLowerCase() === 'accepted' || invitation.status.toLowerCase() === 'declined') &&
      !loading &&
      !!loaded
    ) {
      setState({ ...state, isSubmitting: false });
    }
  }, [userInfo?.email, loading, loaded, invitation?.status]);

  useEffect(() => {
    if (hasOnlyOneSystemAvailable) {
      setSelectedSystems(systemsWhereUserIsAdmin);
    }
  }, [systemsWhereUserIsAdmin.length]);

  useEffect(() => {
    setState({ ...state, invitationId: match.params.id });
  }, []);

  const handleAccept = async () => {
    const { invitationId } = state;

    setState({ ...state, isSubmitting: true, showError: false });
    const response = await acceptInvitation({
      invitationId,
      groupIds: selectedSystems.map((system) => system.id),
      userId: userInfo.id,
      servicePartnerId: invitation.servicePartnerId,
    });

    if (!response) {
      setState({
        ...state,
        showError: true,
        isSubmitting: false,
      });
    }
  };

  const handleDecline = async () => {
    setState({ ...state, isSubmitting: true, showError: false });
    const response = await declineInvitation(state.invitationId);

    if (!response) {
      setState({
        ...state,
        showError: true,
        isSubmitting: false,
      });
    }
  };

  const toggleLegalModal = (legalType) => {
    setState({
      ...state,
      showLegalInfoModal: !state.showLegalInfoModal,
      legalInfoType: legalType,
    });
  };

  const handleSelectSystem = (isSelected, system) => {
    if (!isSelected) {
      setSelectedSystems([...selectedSystems, system]);
    } else {
      setSelectedSystems(selectedSystems.filter((selectedSystem) => selectedSystem.id !== system.id));
    }
  };

  const toggleAllSelected = () => {
    if (hasOnlyOneSystemAvailable) {
      return;
    }

    const systemsThatAreNotSelected = filteredSystemList.filter(
      (partial) => !selectedSystems.some((system) => system.id === partial.id)
    );

    if (systemsThatAreNotSelected.length > 0) {
      setSelectedSystems([...selectedSystems, ...systemsThatAreNotSelected]);
      return;
    }

    const filteredSelection = selectedSystems.filter(
      (system) => !filteredSystemList.some((partial) => system.id === partial.id)
    );
    setSelectedSystems(filteredSelection);
    return;
  };

  const shouldEnableAccept =
    selectedSystems.length > 0 && selectedSystems.length <= maxNumberOfGroups && !state.isSubmitting;

  return (
    <div className="page-content">
      <div className="invitation">
        <div className="invitation__wrapper row">
          <div className="col-lg-12">
            <h1 id="invitation">
              <FormattedMessage id="invitation.title" defaultMessage="Invitation" />
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">{(valid === null || state.isSubmitting === true) && <Spinner />}</div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            {valid === false && (
              <div className="alert alert-danger">
                <FormattedMessage
                  id="invitation.invalid"
                  defaultMessage="You have reached an invitation that is invalid. Please check the link and try again."
                />
              </div>
            )}
          </div>
        </div>

        {valid === true && invitation.status.toLowerCase() === 'invited' && state.isSubmitting === false && (
          <div>
            <div className="row">
              <div className="col-lg-12">
                <p className="intro-1">
                  <FormattedHTMLMessage
                    id="invitation.intro.web.1"
                    defaultMessage="Here you can respond to an invitation to connect {count, plural, one {a system} other {systems} } to one of your technicians. You can select up to <b>({count})</b> {count, plural, one {system} other {systems} }. If you choose to accept this, your chosen {count, plural, one {system} other {systems} } will be linked to your technician. Your technician will be able to view, manage and make changes to your {count, plural, one {system} other {systems} }. You can choose to disconnect your technician at any time from the system's security page. myUplink takes no responsibility for what your technician does to your {count, plural, one {system} other {systems} }."
                    values={{ count: maxNumberOfGroups }}
                  />
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-3">
                <p className="bold-text">
                  <FormattedMessage id="invitation.yourTechnician" defaultMessage="Your Technician" />:{' '}
                </p>
              </div>
              <div className="col-sm-9">
                <p className="bold-text">{yourTechnicianInfo.name}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <p className="bold-text">
                  <FormattedMessage id="invitation.yourTechnicianNote" defaultMessage="Your Technician Note" />:{' '}
                </p>
              </div>
              <div className="col-sm-9">
                <p className="bold-text error">{invitation.additionalNote}</p>
              </div>
            </div>
            <div style={{ paddingTop: '1.5rem' }} />
            {systemsWhereUserIsAdmin.length > 0 && (
              <div className="row invitation-system-list-row">
                <div className="col-sm-3">
                  <p className="bold-text">
                    <FormattedMessage id="invitation.selectSystem" defaultMessage="Select System" />:{' '}
                  </p>
                </div>
                <div className="col-sm-9 invitation-system-list-wrapper">
                  <SystemList
                    filteredSystemList={filteredSystemList}
                    maxNumberOfGroups={maxNumberOfGroups}
                    selectedSystems={selectedSystems}
                    handleSelectSystem={handleSelectSystem}
                    toggleAllSelected={toggleAllSelected}
                    allSelected={allSelected}
                    setSearchTerm={setSearchTerm}
                    searchTerm={searchTerm}
                    hasOnlyOneSystemAvailable={hasOnlyOneSystemAvailable}
                  />
                </div>
              </div>
            )}

            {(!systems || systems.length === 0 || systemsWhereUserIsAdmin.length === 0) && (
              <div className="row">
                <div className="col-lg-12">
                  <p className="bold-text" style={divStylePaddingTop}>
                    <FormattedMessage
                      id="invitation.noSystems"
                      defaultMessage="It looks like you don't have any systems connected to your account."
                    />
                  </p>
                </div>
              </div>
            )}

            <div className="row intro">
              <FormattedHTMLMessage id="invitation.intro.web.2" />
              <span className="link" onClick={() => toggleLegalModal('privacy-policy')}>
                <FormattedHTMLMessage id="invitation.intro.2.link" />
              </span>
            </div>

            <div className="button-wrapper">
              <Button className="button--default" type="button" onClick={handleDecline}>
                <FormattedMessage id="invitation.decline" defaultMessage="Decline" />
              </Button>

              <Button className="button--secondary" type="button" disabled={!shouldEnableAccept} onClick={handleAccept}>
                <FormattedMessage id="invitation.accept" defaultMessage="Accept" />
              </Button>
            </div>
          </div>
        )}

        {state.showError && (
          <div className="alert alert-danger">
            <FormattedHTMLMessage
              id="invitation.somethingWentWrong"
              defaultMessage="Something went wrong! Please try again later."
            />
          </div>
        )}

        {valid === true && invitation.status.toLowerCase() === 'accepted' && (
          <div>
            <div className="alert alert-info">
              <FormattedMessage id="invitation.accepted" defaultMessage="This invitation has been accepted." />
            </div>
          </div>
        )}

        {valid === true && invitation.status.toLowerCase() === 'declined' && (
          <div>
            <div className="alert alert-info">
              <FormattedMessage id="invitation.declined" defaultMessage="This invitation has been declined." />
            </div>
          </div>
        )}

        {state.showLegalInfoModal && state.legalInfoType && (
          <Modal
            show={state.showLegalInfoModal}
            backdrop={true}
            onHide={() =>
              setState({
                ...state,
                showLegalInfoModal: !state.showLegalInfoModal,
              })
            }
          >
            <ModalBody>
              <FormattedHTMLMessage id={state.legalInfoType} />
            </ModalBody>
          </Modal>
        )}
      </div>
    </div>
  );
};

Invitation.propTypes = {
  match: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  invitation: PropTypes.object.isRequired,
  systems: PropTypes.array.isRequired,
  yourTechnicianInfo: PropTypes.object.isRequired,
  valid: PropTypes.bool,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  getInvitation: PropTypes.func,
  acceptInvitation: PropTypes.func,
  declineInvitation: PropTypes.func,
};

export default connect(
  ({ app: { userInfo }, invitation, systems, yourTechnicianInfo, valid }) => ({
    userInfo,
    ...invitation,
    ...systems,
    ...yourTechnicianInfo,
    ...valid,
  }),
  { getInvitation, acceptInvitation, declineInvitation }
)(Invitation);
