import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Spinner } from 'jpi-cloud-web-ui-components';

import BrandStylesLoader from './BrandStylesLoader';
import { Brands } from '../../assets/brands/brands';

import { ELIGIBLE_BRANDS_FOR_MAVENOID, ELIGIBLE_NIBEF_FIRMWARES_FOR_MAVENOID } from '../constants/constants';

import {
  initUser,
  getUserSystems,
  getUserInfo,
  getCountries,
  getLatestAgreementsVersions,
  getCloudStatus,
  getLastUsedSystem,
  getLastUsedLanguage,
  setAppLoadedStatus,
  setAppLoadingStatus,
  setUserDataState,
  getAllLanguages,
  getSupportedLanguages,
  setDeviceUpdateAvailable,
} from './actions';
import { getDevices, getSubscriptionsInGroup } from '../pages/Devices/actions';
import { languageSelected, setLastUsedLanguage } from '../layout/LanguageSelector/actions';
import { getFeatures } from '../FeaturesFlags/actions';
import { UserDataState } from './user-data-state';
import { preventGoogleFontsLoading, removeCart } from './utils';
import { Mavenoid } from '../Mavenoid';

import * as lastUsedSystem from '../../api/systems';
import { removeFirstChart, removeSecondChart } from '../../api/history';
import { withAppInsights } from '../tracking/AppInsights';
import { getUpgradeableDeviceIds } from '../../utils';
import { getPaidBrands } from '../Shop/client';

class AppInitializer extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    initUser: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func.isRequired,
    getUserSystems: PropTypes.func.isRequired,
    getDevices: PropTypes.func.isRequired,
    getSubscriptionsInGroup: PropTypes.func.isRequired,
    getFeatures: PropTypes.func.isRequired,
    getCountries: PropTypes.func,
    getLatestAgreementsVersions: PropTypes.func.isRequired,
    getCloudStatus: PropTypes.func.isRequired,
    setAppLoadedStatus: PropTypes.func.isRequired,
    setAppLoadingStatus: PropTypes.func.isRequired,
    setUserDataState: PropTypes.func.isRequired,
    languageSelected: PropTypes.func.isRequired,
    getLastUsedSystem: PropTypes.func.isRequired,
    getLastUsedLanguage: PropTypes.func.isRequired,
    setLastUsedLanguage: PropTypes.func.isRequired,
    setDeviceUpdateAvailable: PropTypes.func.isRequired,
    selectedSystem: PropTypes.object,
    systems: PropTypes.array,
    userLoggedIn: PropTypes.bool,
    url: PropTypes.object,
    countries: PropTypes.arrayOf(PropTypes.object),
    userInfo: PropTypes.object,
    lastUsedSystemId: PropTypes.string,
    lastUsedSystemBrand: PropTypes.string,
    lastUsedLanguage: PropTypes.string,
    appLoaded: PropTypes.bool,
    appLoading: PropTypes.bool,
    userDataState: PropTypes.string,
    getAllLanguages: PropTypes.func.isRequired,
    getSupportedLanguages: PropTypes.func.isRequired,
    selectedLanguage: PropTypes.string.isRequired,
    getPaidBrands: PropTypes.func,
  };

  static defaultProps = {
    userLoggedIn: false,
  };

  state = {
    refreshIntervalCycle: null,
  };

  async componentDidMount() {
    preventGoogleFontsLoading();
    // Here we assume that componentDidMount was occurred because of language change
    // In this case we don't need to do any initializations because it was done before
    !this.props.appLoaded && !this.props.appLoading && (await this.initApp());
    this.startRefreshIntervalCycle(async () => {
      await this.props.getCloudStatus();
    });
  }

  startRefreshIntervalCycle = (handler) => {
    const second = 1000;
    const minute = 60;
    const refreshIntervalCycle = setInterval(handler, 5 * minute * second);
    this.setState({ refreshIntervalCycle });
  };

  disposeRefreshIntervalCycle = (isSettable) => {
    if (this.state.refreshIntervalCycle) {
      clearInterval(this.state.refreshIntervalCycle);
      if (isSettable) {
        this.setState({ refreshIntervalCycle: null });
      }
    }
  };

  componentWillUnmount() {
    this.disposeRefreshIntervalCycle(false);
  }

  async componentDidUpdate() {
    !this.props.appLoaded && !this.props.appLoading && (await this.initApp());
    if (this.props.userLoggedIn && this.props.userDataState === UserDataState.REQUESTED) {
      await this.props.setUserDataState(UserDataState.LOADING);

      const initDataPromise = this.setInitialUserDataState();
      const loadSystemsPromise = this.loadSystems();
      await initDataPromise;
      await loadSystemsPromise;
      await this.props.setUserDataState(UserDataState.LOADED);
    }
  }

  async initApp() {
    await this.props.setAppLoadingStatus(true);

    await this.props.initUser();

    const getCountriesPromise = this.props.getCountries();
    const getLatestAgreementsVersionsPromise = this.props.getLatestAgreementsVersions();
    const getCloudStatusPromise = this.props.getCloudStatus();

    const { userLoggedIn } = this.props;

    if (userLoggedIn && this.props.userDataState == UserDataState.EMPTY) {
      await this.props.setUserDataState(UserDataState.REQUESTED);
    }

    if (!userLoggedIn) {
      removeFirstChart();
      removeSecondChart();
      removeCart();
    }

    // All these requests are independent and can be run in parallel to improve site loading speed.
    await getCountriesPromise;
    await getLatestAgreementsVersionsPromise;
    await getCloudStatusPromise;

    await this.props.setAppLoadedStatus(true);
    await this.props.setAppLoadingStatus(false);
  }

  async loadSystems() {
    const {
      data: { id: lastUsedSystemId },
    } = await lastUsedSystem.get();
    const system = await this.props.getUserSystems(lastUsedSystemId);
    await this.props.getLastUsedSystem();

    if (system) {
      // run non-blocking requests in parallel to improve page loading performance
      const parallelRequests = [this.props.getSubscriptionsInGroup(system.id)];
      const devices = await this.props.getDevices(system.id);
      if (devices && devices.length > 0) {
        parallelRequests.push(this.props.getFeatures(devices[0].id));

        // Check if there any firmware update available
        const upgradableDeviceIds = getUpgradeableDeviceIds(devices);

        if (upgradableDeviceIds.length > 0) {
          this.props.setDeviceUpdateAvailable(true);
        }
      }

      await Promise.all(parallelRequests);
    }
  }

  async setInitialUserDataState() {
    const getUserInfoPromise = !this.props.userInfo ? this.props.getUserInfo() : Promise.resolve(); // empty promise if user data is already initialized

    this.props.getPaidBrands();

    // run requests in parallel
    await Promise.all([getUserInfoPromise, this.props.getAllLanguages(), this.props.getSupportedLanguages()]);

    await this.setInitialLanguage();
    await this.props.getLastUsedLanguage();

    if (
      this.props.userInfo.preferredLanguage &&
      this.props.lastUsedLanguage !== this.props.userInfo.preferredLanguage
    ) {
      this.props.languageSelected(this.props.userInfo.preferredLanguage, { id: null });
      this.props.setLastUsedLanguage(this.props.userInfo.preferredLanguage);
    }
  }

  async setInitialLanguage() {
    await this.props.getLastUsedLanguage();

    const preferredLanguage =
      (this.props.userInfo && this.props.userInfo.preferredLanguage) || this.props.lastUsedLanguage || 'en-US';

    this.props.languageSelected(preferredLanguage, { id: null });
    this.props.setLastUsedLanguage(preferredLanguage);
  }

  render() {
    const { selectedSystem, userInfo, selectedLanguage, userLoggedIn, systems } = this.props;
    const isAppReady = !(
      !this.props.appLoaded ||
      this.props.userDataState === UserDataState.REQUESTED ||
      this.props.userDataState === UserDataState.LOADING
    );

    const defaultBrandId = 'myuplink';
    const lastUsedSystemBrand = this.props.lastUsedSystemBrand;
    let brandId = (
      selectedSystem?.brandId ??
      (systems.length > 0 ? lastUsedSystemBrand : defaultBrandId) ??
      defaultBrandId
    ).toLowerCase();
    if (!Object.values(Brands).some((b) => b === brandId)) {
      brandId = defaultBrandId;
    }

    const checkBrandAndFirmware = (brandId) => {
      if (brandId === Brands.NIBEF) {
        return ELIGIBLE_NIBEF_FIRMWARES_FOR_MAVENOID.includes(selectedSystem.devices[0]?.firmwareId);
      }
      return ELIGIBLE_BRANDS_FOR_MAVENOID.includes(brandId);
    };

    const showMavenoid = checkBrandAndFirmware(brandId) && userLoggedIn;

    return (
      <BrandStylesLoader brandId={brandId}>
        {isAppReady && this.props.children}
        {!isAppReady && <Spinner dark />}
        <Mavenoid
          key={selectedLanguage}
          show={showMavenoid}
          email={userInfo && userInfo.email}
          language={selectedLanguage}
        />
      </BrandStylesLoader>
    );
  }
}

const withConnect = connect(({ app, language: { selectedLanguage } }) => ({ ...app, selectedLanguage }), {
  initUser,
  getUserInfo,
  getUserSystems,
  getDevices,
  getSubscriptionsInGroup,
  getFeatures,
  getCountries,
  getLatestAgreementsVersions,
  getCloudStatus,
  setAppLoadedStatus,
  setAppLoadingStatus,
  setUserDataState,
  getLastUsedSystem,
  getLastUsedLanguage,
  setLastUsedLanguage,
  languageSelected,
  getAllLanguages,
  getSupportedLanguages,
  setDeviceUpdateAvailable,
  getPaidBrands,
});

export default compose(withRouter, withConnect, withAppInsights)(AppInitializer);
