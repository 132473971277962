import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { Link } from '../../Link';
import './login.scss';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import AcceptAgreementConfirmation from './components/AcceptAgreementsConfirmation';
import MigrationConfirmation from './components/MigrationConfirmation';
import { sendConfirmationLink } from '../Register/actions';
import {
  validateUserCredentials,
  setUpUserAgreements,
  validateOldUserCredentials,
  migrateOldUser,
  login,
  loginWithAzureB2CAction,
} from './actions';
import { setUserDataState } from '../../AppInitializer/actions';
import { UserDataState } from '../../AppInitializer/user-data-state';
import { formatErrorMessage } from '../../../localization/message-formatting';
import { passwordMaxLength, emailAddress } from '../../constants/constants';
import { a11yKeyPress } from '../../../utils';
import { ENV } from '../../../api';
import { getAzureB2CLoginLink } from '../../../api/azureB2C';
import { NotificationManager } from 'react-notifications';
import Spacer from '../../layout/Spacer';

const schema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .email('username.email')
    .required('username.required')
    .max(emailAddress.maxLength, 'username.maxlength'),
  password: yup.string().trim().required('password.required').max(passwordMaxLength, 'password.maxlength'),
});

const errorMessages = {
  'username.email': {
    id: 'login.error.validation.username.email',
    defaultMessage: 'Username should be email address',
  },
  'username.required': {
    id: 'login.error.validation.username.required',
    defaultMessage: 'Username is mandatory field',
  },
  'username.maxlength': {
    id: 'email.error.validation.maxlength',
    defaultMessage: 'Email address cannot be longer than 255 characters',
  },
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  'password.maxlength': {
    id: 'password.error.validation.maxlength',
    defaultMessage: 'Password must be no longer than 128 characters',
  },
  invalid_username_or_password: {
    id: 'login.error.request.invalid',
    defaultMessage: 'Invalid username or password',
  },
  unknown: {
    id: 'generic.error.request.unknown',
    defaultMessage: 'An error has occurred. Try again later.',
  },
};

const getRedirectToFromQueryString = (qs) => {
  const parsedQueryString = queryString.parse(qs);
  return parsedQueryString.redirectTo;
};

const LoginFormInner = ({
  onSubmit,
  requestError,
  isSubmitting,
  intl,
  loginWithAzureB2C,
  showEmailNotConfirmErrorMessage,
  moveToSendConfirmEmailPage,
}) => (
  <Formik initialValues={{ username: '', password: '' }} validationSchema={schema} onSubmit={onSubmit}>
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      /* and other goodies */
    }) => (
      <form onSubmit={handleSubmit} className="form login-form" autoComplete="off" aria-label="The Log in form">
        <h1 className="form__heading" id="login">
          <FormattedMessage id="login.header" defaultMessage="Login" />
        </h1>
        <Spacer y={30} />
        <label className="hidden" htmlFor="username">
          username
        </label>
        <FormattedMessage id="login.input-name" defaultMessage="E-mail">
          {(placeholder) => (
            <Input
              placeholder={placeholder}
              type="text"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              value={values.username}
              aria-required="true"
              error={errors.username && touched.username && formatErrorMessage(intl, errorMessages, errors.username)}
            />
          )}
        </FormattedMessage>
        <Spacer y={25} />
        <label className="hidden" htmlFor="password">
          password
        </label>
        <FormattedMessage id="login.input-password" defaultMessage="Password">
          {(placeholder) => (
            <Input
              placeholder={placeholder}
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              aria-required="true"
              maxLength={passwordMaxLength + 1}
              error={errors.password && touched.password && formatErrorMessage(intl, errorMessages, errors.password)}
            />
          )}
        </FormattedMessage>
        <Spacer y={15} />
        <div className="forgot-link-wrapper">
          <Link className="form__link" to="/forgot-password">
            <FormattedMessage id="login.forgot-password" defaultMessage="Forgot your password?" />
          </Link>
        </div>
        {requestError !== 'email_not_confirmed' && (
          <p className="text-danger">{formatErrorMessage(intl, errorMessages, requestError)}</p>
        )}
        {showEmailNotConfirmErrorMessage === 'showEmailNotConfirmError' && (
          <p className="text-danger confirmation_message">
            <FormattedMessage
              id="email.not.confirmed"
              defaultMessage="E-mail address has not been verified yet. Please click {here} to resend your verification email."
              values={{
                here: (
                  <u
                    className="email-not-confirmed-message"
                    onClick={() => moveToSendConfirmEmailPage('showSendEmailConfirmLinkPage')}
                    onKeyUp={a11yKeyPress(() => moveToSendConfirmEmailPage('showSendEmailConfirmLinkPage'))}
                    role="button"
                    tabIndex={0}
                  >
                    <FormattedMessage id="email.here" defaultMessage="here" />
                  </u>
                ),
              }}
            />
          </p>
        )}
        <Spacer y={50} />
        <div className="button-wrapper--large">
          <Button className="link--secondary" type="submit" disabled={isSubmitting}>
            <FormattedMessage id="login.button" defaultMessage="Login" />
          </Button>
          <Spacer y={10} />
          <FormattedMessage id="register.or" defaultMessage="or" />
          <Spacer y={10} />
          <Link tagName="button" className="button button--primary" to="/register">
            <FormattedMessage id="register.heading" defaultMessage="Create your account" />
          </Link>
        </div>
        {ENV !== 'production' && (
          <>
            <Spacer y={10} />
            <Button type="button" className="button--default" onClick={loginWithAzureB2C}>
              <FormattedMessage id="login.azureb2c" defaultMessage="Azure B2C Login" />
            </Button>
          </>
        )}
      </form>
    )}
  </Formik>
);

LoginFormInner.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  intl: PropTypes.object,
  loginWithAzureB2C: PropTypes.func.isRequired,
  showEmailNotConfirmErrorMessage: PropTypes.string.isRequired,
  moveToSendConfirmEmailPage: PropTypes.func.isRequired,
};

const LoginForm = injectIntl(LoginFormInner);

const resendConfirmationEmailSchema = yup.object().shape({
  username: yup.string().trim().email('username.email').required('username.required'),
});

const ResendConfirmationEmailForm = ({ onSubmit, intl, backToLogin, isSubmitting, isLinkSended }) => {
  return (
    <Formik initialValues={{ username: '' }} validationSchema={resendConfirmationEmailSchema} onSubmit={onSubmit}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit} className="form" autoComplete="off">
          <h3 className="form__heading">
            <FormattedMessage id="sendConfirmEmailLink.heading" defaultMessage="Send confirmation email" />
          </h3>
          <FormattedMessage id="login.input-name" defaultMessage="E-mail">
            {(placeholder) => (
              <Input
                placeholder={placeholder}
                type="text"
                name="username"
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
                value={values.username}
                aria-required="true"
                error={errors.username && touched.username && formatErrorMessage(intl, errorMessages, errors.username)}
              />
            )}
          </FormattedMessage>
          {isLinkSended && (
            <p className="text-danger confirmation_message">
              <FormattedMessage
                id="sendConfirmation.heading"
                defaultMessage="Confirmation link was sent to your email"
              />
            </p>
          )}
          <div className="button-wrapper--large">
            <Button className="button--primary" type="submit" disabled={isSubmitting}>
              <FormattedMessage id="sendConfirmEmailLinkButton.heading" defaultMessage="Send Email" />
            </Button>
            <Spacer y={10} />
            <FormattedMessage id="register.or" defaultMessage="or" />
            <Spacer y={10} />
            <Button className="button button--secondary create" onClick={() => backToLogin('')}>
              <FormattedMessage id="login.button" defaultMessage="Log in" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

ResendConfirmationEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  intl: PropTypes.object,
  backToLogin: PropTypes.func.isRequired,
  isLinkSended: PropTypes.bool.isRequired,
};

const ResendConfirmationEmail = injectIntl(ResendConfirmationEmailForm);

class Login extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    error: PropTypes.string,
    validateUserCredentials: PropTypes.func.isRequired,
    validateOldUserCredentials: PropTypes.func.isRequired,
    setUpUserAgreements: PropTypes.func.isRequired,
    migrateOldUser: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    acceptedAgreements: PropTypes.object,
    modalError: PropTypes.any,
    oldUserId: PropTypes.any,
    register: PropTypes.object,
    selectedLanguage: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    setUserDataState: PropTypes.func.isRequired,
    latestAgreements: PropTypes.object.isRequired,
    sendConfirmationLink: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    loginWithAzureB2CAction: PropTypes.func.isRequired,
  };

  state = {
    showAgreementsModal: false,
    email: null,
    password: null,
    showMigrationConfirmation: false,
    showError: false,
    showLegalInfoModal: false,
    legalInfoType: null,
    showEmailConfirmationStatus: '',
    userId: '',
    isLinkSended: false,
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(location.search);

    const codeParam = queryParams.get('code');
    if (codeParam) {
      const stateParam = queryParams.get('state');
      this.props.setUserDataState(UserDataState.REQUESTED);
      this.props.loginWithAzureB2CAction(codeParam, stateParam);
    }

    const errorDescriptionParam = queryParams.get('error_description');
    if (errorDescriptionParam) {
      NotificationManager.error(errorDescriptionParam);
    }

    document.title = this.props.intl.formatMessage({
      id: 'login.page-title',
      defaultMessage: 'myUplink - Login to connect and manage your compatible smart home energy systems',
    });
  }

  toggleLegalModal = (legalType) => {
    this.setState({
      showLegalInfoModal: !this.state.showLegalInfoModal,
      legalInfoType: legalType,
    });
  };

  onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ ...this.state, showError: true, showEmailConfirmationStatus: '' });
    const validateUserCredentialsResult = await this.props.validateUserCredentials(
      values.username.trim(),
      values.password.trim()
    );

    if (!validateUserCredentialsResult.isOk) {
      if (await this.props.validateOldUserCredentials(values.username.trim(), values.password.trim())) {
        this.setState({
          ...this.state,
          showMigrationConfirmation: true,
          email: values.username.trim(),
          password: values.password.trim(),
        });
      }
    } else if (validateUserCredentialsResult.isOk && !validateUserCredentialsResult.isEmailConfirmed) {
      this.setState({
        ...this.state,
        showEmailConfirmationStatus: 'showEmailNotConfirmError',
        userId: validateUserCredentialsResult.userId,
      });
    } else if (
      this.props.acceptedAgreements &&
      (this.props.acceptedAgreements.acceptedPrivacyPolicy < this.props.latestAgreements.privacyPolicy ||
        this.props.acceptedAgreements.acceptedAgreement < this.props.latestAgreements.termsOfService)
    ) {
      this.setState({
        ...this.state,
        showAgreementsModal: true,
        email: values.username.trim(),
        password: values.password.trim(),
      });
    } else {
      await this.props.setUserDataState(UserDataState.REQUESTED);

      if (await this.props.login(values.username.trim(), values.password.trim())) {
        this.props.push(getRedirectToFromQueryString(this.props.location.search) || '/');
      } else {
        await this.props.setUserDataState(UserDataState.EMPTY);
      }
    }

    setSubmitting(false);
  };

  onMigrationConfirm = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (this.props.oldUserId) {
      if (
        !(await this.props.migrateOldUser(
          this.props.oldUserId,
          this.props.latestAgreements.termsOfService,
          this.props.latestAgreements.privacyPolicy
        ))
      ) {
        setSubmitting(false);
        return;
      }
      await this.props.setUserDataState(UserDataState.REQUESTED);

      if (await this.props.login(this.state.email, this.state.password)) {
        this.props.push('/');
      } else {
        await this.props.setUserDataState(UserDataState.EMPTY);
      }
    }
    setSubmitting(false);
  };

  onMigrationDiscard = async () => {
    this.setState({ ...this.state, showMigrationConfirmation: false, email: null, password: null });
  };

  onAgreementsSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (
      await this.props.setUpUserAgreements(
        this.state.email,
        this.state.password,
        this.props.latestAgreements.termsOfService,
        this.props.latestAgreements.privacyPolicy
      )
    ) {
      this.setState({ ...this.state, showAgreementsModal: false });

      await this.props.setUserDataState(UserDataState.REQUESTED);

      if (await this.props.login(this.state.email, this.state.password)) {
        this.props.push('/');
      }
    }
    setSubmitting(false);
  };

  onSendConfirmLink = async (values, { setSubmitting }) => {
    setSubmitting(true);
    this.setState({ ...this.state, isLinkSended: true });

    if (values.username.trim()) {
      await this.props.sendConfirmationLink(this.state.userId);
    }

    setSubmitting(false);

    setTimeout(
      function () {
        this.setState({ ...this.state, isLinkSended: false });
      }.bind(this),
      5000
    );
  };

  toggleModal = () => {
    this.setState({ ...this.state, showAgreementsModal: !this.state.showAgreementsModal });
  };

  onChangingEmailConfirmationStatus = (val) => {
    this.setState({ ...this.state, showEmailConfirmationStatus: val, isLinkSended: false });
  };

  loginWithAzureB2C = async () => {
    window.location.href = await getAzureB2CLoginLink({ language: this.props.selectedLanguage });
  };

  render() {
    return (
      <>
        {this.state.showEmailConfirmationStatus === 'showSendEmailConfirmLinkPage' ? (
          <ResendConfirmationEmail
            push={this.props.push}
            onSubmit={this.onSendConfirmLink}
            requestError={this.state.showError ? this.props.error : ''}
            backToLogin={this.onChangingEmailConfirmationStatus}
            isLinkSended={this.state.isLinkSended}
            isSubmitting={this.props.loading}
          />
        ) : (
          <LoginForm
            push={this.props.push}
            onSubmit={this.onSubmit}
            loginWithAzureB2C={this.loginWithAzureB2C}
            showEmailNotConfirmErrorMessage={this.state.showEmailConfirmationStatus}
            requestError={this.state.showError ? this.props.error : ''}
            moveToSendConfirmEmailPage={this.onChangingEmailConfirmationStatus}
            isSubmitting={this.props.loading}
          />
        )}
        {this.state.showAgreementsModal && this.props.acceptedAgreements && (
          <AcceptAgreementConfirmation
            acceptedAgreements={this.props.acceptedAgreements}
            latestAgreements={this.props.latestAgreements}
            requestError={this.props.modalError}
            onSubmit={this.onAgreementsSubmit}
            toggleModal={this.toggleModal}
            showModal={this.state.showAgreementsModal}
          />
        )}
        {this.state.showMigrationConfirmation && this.props.oldUserId && (
          <MigrationConfirmation
            isShown={this.state.showMigrationConfirmation}
            requestError={this.props.modalError}
            onDiscard={this.onMigrationDiscard}
            onConfirm={this.onMigrationConfirm}
            state={this.state}
            toggleLegalModal={this.toggleLegalModal}
          >
            <FormattedMessage
              id="migrations.confirmation.title"
              defaultMessage="Since you have an NIBE Uplink account, Your data will be copied"
            />
          </MigrationConfirmation>
        )}
      </>
    );
  }
}
export default connect(
  ({ login, register, app, language }) => ({
    ...login,
    register,
    selectedLanguage: language.selectedLanguage,
    latestAgreements: app.latestAgreements,
  }),
  {
    push,
    login,
    validateUserCredentials,
    validateOldUserCredentials,
    setUpUserAgreements,
    migrateOldUser,
    setUserDataState,
    sendConfirmationLink,
    loginWithAzureB2CAction,
  }
)(injectIntl(Login));
