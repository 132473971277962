import {
  GET_REGISTERED_PRODUCTS_REQUEST,
  GET_REGISTERED_PRODUCTS_RESPONSE,
  FIND_PRODUCT_REQUEST,
  FIND_PRODUCT_RESPONSE,
  REGISTER_PRODUCT_REQUEST,
  REGISTER_PRODUCT_RESPONSE,
  GET_YOUR_TECHNICIANS_LIST_REQUEST,
  GET_YOUR_TECHNICIANS_LIST_RESPONSE,
  GET_YOUR_TECHNICIAN_BY_NAME_HINT_REQUEST,
  GET_YOUR_TECHNICIAN_BY_NAME_HINT_RESPONSE,
} from './actions';

import { LOGOUT_USER } from '../Login/actions';

const initialState = {
  productList: [],
  productFound: { canBeRegistered: false, validCountries: [] },
  registrationSucceeded: false,
  serialNumberSearchSubmitted: false,
  registrationFormSubmitted: false,
  yourTechnician: {},
  yourTechnicianResult: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGISTERED_PRODUCTS_REQUEST:
      return {
        ...state,
        productList: [],
        productFound: { canBeRegistered: false, validCountries: [] },
        registrationSucceeded: false,
        serialNumberSearchSubmitted: false,
        registrationFormSubmitted: false,
      };
    case GET_REGISTERED_PRODUCTS_RESPONSE:
      return { ...state, productList: action.productList };
    case FIND_PRODUCT_REQUEST:
      return { ...state, productFound: {}, serialNumberSearchSubmitted: false };
    case FIND_PRODUCT_RESPONSE:
      return { ...state, productFound: action.productFound, serialNumberSearchSubmitted: true };
    case REGISTER_PRODUCT_REQUEST:
      return { ...state, registrationSucceeded: false, registrationFormSubmitted: false };
    case REGISTER_PRODUCT_RESPONSE:
      return { ...state, registrationSucceeded: action.registrationSucceeded, registrationFormSubmitted: true };
    case LOGOUT_USER:
      return {
        ...state,
        productList: [],
        productFound: { canBeRegistered: false, validCountries: [] },
        registrationSucceeded: false,
        serialNumberSearchSubmitted: false,
        registrationFormSubmitted: false,
      };
    case GET_YOUR_TECHNICIANS_LIST_REQUEST:
      return { ...state, yourTechnician: {} };
    case GET_YOUR_TECHNICIANS_LIST_RESPONSE:
      return { ...state, yourTechnician: action.yourTechnician };
    case GET_YOUR_TECHNICIAN_BY_NAME_HINT_REQUEST:
      return { ...state, yourTechnicianResult: [] };
    case GET_YOUR_TECHNICIAN_BY_NAME_HINT_RESPONSE:
      return { ...state, yourTechnicianResult: action.yourTechnicianResult };
    default:
      return state;
  }
};
