import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as client from '../../../../Shop/client';
import { Button, Title } from 'jpi-cloud-web-ui-components';
import OrderDetails from './components/OrderDetails';
import ProductDetails from './components/ProductDetails';
import PaymentDetails from './components/PaymentDetails';
import { isStripeSpecificBrand as isStripeBrand, redirectUserToPaymentWindow } from '../storeUtils';

import './orderpage.scss';
import { get, isEmpty } from 'lodash';
import { status } from './components/constants';

const orderPage = ({
  order,
  closeCreatedOrder,
  cancelOrder,
  getOrderPaymentProviders,
  paymentProviders,
  language,
  paymentDetails,
  getOrderStatus,
  selectedSystem,
  payPendingOrder,
  paidBrands,
}) => {
  const [isPayOrderClicked, setPayOrderClicked] = useState(false);
  const [isPendingOrder, setPendingOrder] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const { brandId = '' } = selectedSystem || {};
  const { stripeSpecificPaidBrands } = paidBrands;
  const isStripeSpecificBrand = isStripeBrand(stripeSpecificPaidBrands, brandId);

  const closeCurrentOrder = () => {
    closeCreatedOrder();
    clearInterval(intervalId);
  };

  const handleCancelOrderButtonClick = (orderId) => {
    cancelOrder(orderId);
  };

  const onPayOrderClick = async () => {
    if (isStripeSpecificBrand) {
      payPendingOrder(order.data.id);
      return;
    }
    if (order && order.data.id) {
      await getOrderPaymentProviders(order.data.id);
      setPayOrderClicked(true);
    }
  };

  useEffect(() => {
    const isPaymentDetails = !isEmpty(paymentDetails?.data) && !isEmpty(paymentProviders) && isPayOrderClicked;
    if (isPaymentDetails) {
      const [{ internalName } = {}] = paymentProviders;
      const { hostedPaymentPageUrl } = paymentDetails.data;
      const { languageTag } = language;
      redirectUserToPaymentWindow(hostedPaymentPageUrl, languageTag, internalName);
    }
  }, [paymentDetails, paymentProviders]);

  const timeInterval = 4000;

  useEffect(() => {
    if (
      order?.data?.status === status.PENDING &&
      !isPendingOrder &&
      get(order.data.payments[0], 'paymentDataJson.transactionId')
    ) {
      setPendingOrder(true);
      const id = setInterval(() => getOrderStatus(order.data.id), timeInterval);
      setIntervalId(id);
    }

    if (order && order.data && order.data.status === status.PAID) {
      setPendingOrder(false);
      clearInterval(intervalId);
    }
  }, [order]);

  const isPending = order.data.status === status.PENDING;
  const titleTranslationId = isPending ? 'shop.order.heading.pending' : 'shop.order.heading';
  const titleTranslationDefaultMessage = isPending ? 'Order Confirmation' : 'Your Receipt';

  return (
    <>
      <div className="OrderPage">
        {!order.loading && order.data && (
          <div className="OrderPage__wrapper">
            <div className="OrderPage__heading">
              <Title
                className="OrderPageTitle"
                titleTranslationId={titleTranslationId}
                defaultMessage={titleTranslationDefaultMessage}
              />
            </div>
            <div className="OrderPage__order-number">
              <FormattedMessage id="shop.order.orderNumber" defaultMessage={'Order No:'} />
              &nbsp; &#35;
              {order.data.id}
            </div>
            <OrderDetails order={order} />
            <ProductDetails order={order} />
            <PaymentDetails order={order} />
            {isPendingOrder &&
            !isEmpty(order.data.payments) &&
            order.data.payments[0]?.paymentDataJson?.transactionId &&
            order?.data?.status === status.PENDING ? (
              <p className="OrderPage__pendingMessage">
                <FormattedMessage
                  id="orders.orderPage.pendingStatusMessage"
                  defaultMessage="Your payment is done. Please wait until the processing is complete."
                />
              </p>
            ) : null}
            <div className="OrderPage__buttonWrapper">
              <Button
                className="OrderPage__back-button"
                onClick={() => {
                  closeCurrentOrder();
                }}
              >
                <FormattedMessage id="orders.orderPage.button.back" defaultMessage="Back to Orders" />
              </Button>
              {(isEmpty(order.data.payments) || !order.data.payments[0]?.paymentDataJson?.transactionId) &&
              order.data.status === status.PENDING ? (
                <div className="OrderPage__payOrCancelWrapper">
                  <Button className="OrderPage__pay-button" onClick={async () => onPayOrderClick()}>
                    <FormattedMessage id="shop.order.payOrderButtonText" defaultMessage="Pay Order" />
                  </Button>
                  <Button
                    className="OrderPage__cancel-button"
                    onClick={() => {
                      handleCancelOrderButtonClick(order.data.id);
                    }}
                  >
                    <FormattedMessage id="shop.order.cancelOrderButtonText" defaultMessage="Cancel Order" />
                  </Button>
                </div>
              ) : (
                <Button
                  className="OrderPage__print-button"
                  onClick={() => {
                    window.print();
                  }}
                >
                  <FormattedMessage id="shop.order.print" defaultMessage="Print" />
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

orderPage.propTypes = {
  order: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  closeCreatedOrder: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  getOrderPaymentProviders: PropTypes.func.isRequired,
  paymentProviders: PropTypes.array,
  productDetails: PropTypes.bool.isRequired,
  language: PropTypes.object.isRequired,
  paymentDetails: PropTypes.object.isRequired,
  getOrderStatus: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  selectedSystem: PropTypes.shape({
    brandId: PropTypes.string.isRequired,
  }),
  paidBrands: PropTypes.shape({
    stripeSpecificPaidBrands: PropTypes.array,
  }),
};

const mapStateToProps = (state) => ({
  order: state.shop.order,
  paymentProviders: state.shop.paymentProviders,
  paymentDetails: state.shop.paymentDetails,
  productDetails: state.shop.productDetails,
  language: state.language,
  selectedSystem: state.app.selectedSystem,
  paidBrands: state.shop.paidBrands,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  closeCreatedOrder() {
    dispatch(client.closeCreatedOrder());
  },
  cancelOrder(orderId) {
    dispatch(client.cancelOrder(orderId));
  },
  getOrderPaymentProviders(orderId) {
    dispatch(client.getOrderPaymentProviders(orderId));
  },
  getOrderStatus(orderId) {
    dispatch(client.getOrderStatus(orderId));
  },
  payPendingOrder(orderId) {
    dispatch(client.payPendingOrder(orderId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(orderPage);
