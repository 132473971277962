/**
 *
 * function to compare strings which returns a number
 * indicating whether stringOne comes before,
 * or after, or is the same as stringTwo in sort order
 * @param stringOne string
 * @param stringTwo string
 * @returns Number
 */
export const compareNumericStrings = (stringOne, stringTwo) => {
  if ('string' === typeof stringOne && 'function' === typeof stringOne.localeCompare) {
    return stringOne.localeCompare(stringTwo);
  }
  const stringToInteger = (str) => parseInt(str.replace(/\D+/g, ''));
  const valueOne = stringToInteger(stringOne);
  const valueTwo = stringToInteger(stringTwo);
  if (valueOne === valueTwo) {
    return 0;
  }
  return valueOne < valueTwo ? -1 : 1;
};

export const compareVersions = (versionOne, versionTwo) => {
  if (typeof versionOne === 'number' && typeof versionTwo === 'number') {
    if (versionOne === versionTwo) {
      return 0;
    }

    return versionOne < versionTwo ? -1 : 1;
  }

  if (!(typeof versionOne === 'string' && typeof versionTwo === 'string')) {
    return null;
  }
  var oneSplitted = versionOne.split('.');
  var twoSplitted = versionTwo.split('.');
  var len = Math.max(versionOne.length, versionTwo.length);

  for (var i = 0; i < len; i++) {
    if (isFirstPartNewerVersion(oneSplitted[i], twoSplitted[i])) {
      return 1;
    } else if (isFirstPartNewerVersion(twoSplitted[i], oneSplitted[i])) {
      return -1;
    }
  }

  return 0;
};

export const isVersionUpdateAvailable = (versionOne, versionTwo) => {
  if (typeof versionOne === 'number' && typeof versionTwo === 'number') {
    return versionOne !== versionTwo;
  }

  if (!(typeof versionOne === 'string' && typeof versionTwo === 'string')) {
    return false;
  }

  if (versionOne && versionTwo && versionOne !== versionTwo) {
    return true;
  }

  return false;
};

const isFirstPartNewerVersion = (versionPartOne, versionPartTwo) => {
  return (
    (versionPartOne && !versionPartTwo && parseInt(versionPartOne) > 0) ||
    parseInt(versionPartOne) > parseInt(versionPartTwo)
  );
};

export const removeCart = () => {
  localStorage.removeItem('STORED_USER_CART_DETAILS');
};

export const getCart = () => {
  const getCartFromstorage = localStorage.getItem('STORED_USER_CART_DETAILS');
  return JSON.parse(getCartFromstorage);
};

export const preventGoogleFontsLoading = () => {
  const head = document.getElementsByTagName('head')[0];

  // Save the original method
  const insertBefore = head.insertBefore;

  // Replace it!
  // @ts-ignore
  head.insertBefore = function (newElement, referenceElement) {
    // @ts-ignore
    if (newElement.href && newElement.href.includes('https://fonts.googleapis.com/css')) {
      // if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0) {
      return;
    }

    insertBefore.call(head, newElement, referenceElement);
  };
};
