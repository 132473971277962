import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { eventEmitter, events } from '../components/tracking/AppInsights';
export * from './points';

export const MY_UPLINK_CLIENT_ID = 'My-Uplink-Web';

export const ENV = process.env.ENV || '';
export const API_URL = process.env.API_URL || '';

export const WEB_URL = process.env.WEB_URL || '';
export const WEB_PRO_URL = process.env.WEB_PRO_URL || '';
export const WEB_DEV_URL = process.env.WEB_DEV_URL || '';

// FIXME: Investigate why this is not replaced in build
// export const AXE_DEBUG = process?.env?.AXE_DEBUG || '';

const CONFIG_JSON = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const CONFIG_FORM = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
};

const sortByPrio = (parameters) => {
  var sortedParameters = [...parameters];

  sortedParameters.sort((paramA, paramB) => {
    if (paramA.prio && !paramB.prio) {
      return -1;
    }

    if (!paramA.prio && paramB.prio) {
      return 1;
    }

    if (paramA.prio && paramB.prio) {
      if (+paramA.prio < +paramB.prio) {
        return -1;
      }
      if (+paramA.prio > +paramB.prio) {
        return 1;
      }
    }
  });

  return sortedParameters;
};

export const issueAuthToken = (username, password, clientId = MY_UPLINK_CLIENT_ID) => {
  const body = new URLSearchParams({
    grant_type: 'password',
    username: username,
    password: password,
    client_id: clientId,
  });
  return API.post(`${API_URL}/oauth/token`, body, CONFIG_FORM);
};

export const validateUserCredentials = (username, password) => {
  const body = {
    grant_type: 'password',
    email: username,
    password: password,
  };
  return API.post(`${API_URL}/v2/users/validate`, body, CONFIG_JSON);
};

export const validateOldUserCredentials = (username, password) => {
  const body = {
    email: username,
    password: password,
  };
  return API.post(`${API_URL}/v2/old-users/validate`, body, CONFIG_JSON);
};

export const migrateOldUser = (oldUserId, acceptedTosVersion, acceptedPrivacyPolicyVersion, newPassword) => {
  const body = {
    acceptedAgreement: acceptedTosVersion,
    acceptedPrivacyPolicy: acceptedPrivacyPolicyVersion,
    newPassword: newPassword,
  };

  return API.put(`${API_URL}/v2/old-users/migrate?&oldUserId=${oldUserId}`, body, CONFIG_JSON);
};

export const setUpAgreements = (username, password, acceptedTosVersion, acceptedPrivacyPolicyVersion) => {
  const body = new URLSearchParams({
    grant_type: 'password',
    email: username,
    password: password,
    acceptedAgreement: acceptedTosVersion,
    acceptedPrivacyPolicy: acceptedPrivacyPolicyVersion,
  });
  return API.put(`${API_URL}/v2/users/updateAgreements`, body, CONFIG_FORM);
};

export const getSmartHomeMode = (groupId) => {
  return API.get(`${API_URL}/v2/groups/${groupId}/smart-home-mode`, CONFIG_JSON);
};

export const setSmartHomeMode = (groupId, smartHomeMode) => {
  const body = {
    smartHomeMode: smartHomeMode,
  };
  return API.put(`${API_URL}/v2/groups/${groupId}/smart-home-mode`, body, CONFIG_JSON);
};

export const issueAnonymousAuthToken = () => issueAuthToken('anonymous', 'password', 'Anonymous-Web-Client');

export const reissueAuthToken = (refreshToken) => {
  const body = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${MY_UPLINK_CLIENT_ID}`;
  return API.post(`${API_URL}/oauth/token`, body, CONFIG_FORM);
};

export const sendRecoveryLink = (email) => {
  const body = { email };
  return API.post(`${API_URL}/v2/users/recovery-link/send`, body, CONFIG_JSON);
};

export const verifyRecoveryToken = (token) => {
  const body = { token };
  return API.post(`${API_URL}/v2/users/recovery-link/verify`, body, CONFIG_JSON);
};

export const resetPassword = (token, password) => {
  const body = { token, password };
  return API.post(`${API_URL}/v2/users/recovery-link/reset-password`, body, CONFIG_JSON);
};

export const createUser = (anonymousToken, user) => {
  return API.post(`${API_URL}/v2/users`, user, {
    ...CONFIG_JSON,
    headers: {
      ...CONFIG_JSON.headers,
      Authorization: `Bearer ${anonymousToken}`,
    },
  });
};

export const getUserInfo = () => {
  return API.get(`${API_URL}/v2/users/me`);
};

export const getAddressFromId = (addressId) => {
  return API.get(`${API_URL}/v2/addresses/${addressId}`);
};

export const getUserSystems = () => {
  return API.get(`${API_URL}/v3/groups/me`);
};

export const getUserSystem = (systemId) => {
  return API.get(`${API_URL}/v3/groups/${systemId}`);
};

export const getGroupStatus = (groupId) => {
  return API.get(`${API_URL}/v3/groups/${groupId}/status`);
};

export const changeSystemName = (systemId, name) => {
  const body = {
    alias: name,
  };
  return API.patch(`${API_URL}/v2/groups/${systemId}/alias`, body, CONFIG_JSON);
};

export const getAlarms = (deviceId, type = 'all', page = 1, pageSize = 10) =>
  API.get(`${API_URL}/v2/devices/${deviceId}/alerts/paged/${type}?page=${page}&pageSize=${pageSize}`);

export const getArchivedAlarms = (deviceId, page = 1, pageSize = 10) =>
  API.get(`${API_URL}/v2/devices/${deviceId}/alerts/archived/paged?page=${page}&pageSize=${pageSize}`);

export const getActiveAidMode = (deviceId) => {
  return API.get(`${API_URL}/v2/devices/${deviceId}/aidMode`);
};

export const resetAlarm = (alarm) => {
  const timeout = 10;
  return API.post(`${API_URL}/v2/devices/${alarm.deviceId}/resetAlarm/${alarm.alarmId}/${timeout}`);
};

export const activateAidModeForAlarm = (alarm, aidMode) => {
  const timeout = 10;
  return API.post(`${API_URL}/v2/devices/${alarm.deviceId}/invoke/setAidMode/${aidMode}/${timeout}`);
};

export const deleteAlarm = (alarm) => {
  return API.delete(`${API_URL}/v2/devices/${alarm.deviceId}/alerts/${alarm.id}`);
};

export const getDevices = async (systemId) => {
  return await API.get(`${API_URL}/v2/groups/${systemId}/devices`);
};

export const disconnectDevice = async (deviceId, groupId) => {
  return await API.delete(`${API_URL}/v2/devices/${deviceId}/${groupId}`);
};

export const disconnectSystem = async (groupId, userId) => {
  return await API.delete(`${API_URL}/v2/groups/${groupId}/${userId}`);
};

export const getTileChartData = (dateRange) => {
  let randomData;
  switch (dateRange) {
    case 'day':
      randomData = [...new Array(24)].map((r, i) => ({ y: Math.round(Math.random() * 300) / 10, x: `${i}:00` }));
      break;
    case 'week':
      randomData = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((r) => ({
        y: Math.round(Math.random() * 300) / 10,
        x: r,
      }));
      break;
    case 'month':
      randomData = [...new Array(31)].map((r, i) => ({ y: Math.round(Math.random() * 300) / 10, x: `${i + 1}/1` }));
      break;
    case 'year':
      randomData = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((r) => ({
        y: Math.round(Math.random() * 300) / 10,
        x: r,
      }));
      break;
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ status: 200, data: randomData });
    }, Math.random() * 3000);
  });
};

export const getRegisteredProducts = async (userId) => {
  return await API.get(`${API_URL}/v2/Registrations/user/${userId}`);
};

export const findProduct = async (serialNumber) => {
  return await API.get(`${API_URL}/v2/Registrations/register/${serialNumber}`);
};

export const registerProduct = async (values, userInfo) => {
  const MYUPLINK_SITE = 'MYUPLINK';

  const address = {
    lineOne: values.address1,
    lineTwo: values.address2,
    postalCode: values.postalCode,
    city: values.city,
    region: values.region,
    country: values.country,
  };
  const newAddress = await API.post(`${API_URL}/v2/addresses/user`, address, CONFIG_JSON);
  if (newAddress.status === 200 || newAddress.status === 201) {
    const body = {
      serialNumber: values.serialNumber,
      operatingHours: values.operatingHours,
      installationDate: values.installationDate,
      registrationDate: values.registrationDate,
      warrantyEndDate: values.warrantyEndDate,
      customerName: values.name,
      userId: userInfo.id,
      userAddressId: userInfo.address.id,
      servicePartnerId: '',
      userEmail: values.email,
      systemAddressId: newAddress.data.id,
      installerName: values.installerName,
      phone: values.phone,
      modelName: values.productName,
      site: MYUPLINK_SITE,
    };
    return API.post(`${API_URL}/v2/Registrations/register`, body, CONFIG_JSON);
  }
  return newAddress;
};

export const getHeatPumpStatus = () => {
  const heatPumpStatuses = ['OFF', 'HEATING', 'COOLING', 'HOT_WATER', 'POOL'];

  const heatPumpComponents = [
    { id: 'compressor1', type: 'compressor', name: 'Compressor 1' },
    { id: 'compressor2', type: 'compressor', name: 'Compressor 2' },
    { id: 'brinePump1', type: 'brinePump', name: 'Brine Pump 1' },
    { id: 'brinePump2', type: 'brinePump', name: 'Brine Pump 2' },
    { id: 'circulationPump', type: 'circulationPump', name: 'Circulation Pump' },
    // Commented because it's not a case when all statuses of heat pump are enabled simultaneously.
    { id: 'heating', type: 'heating', name: 'Heating' },
    // { id: 'cooling', name: 'Cooling' },
    // { id: 'hotWater', name: 'Hot water' },
    { id: 'ventilation', type: 'ventilation', name: 'Ventilation' },
    // { id: 'pool', name: 'Pool' },
    { id: 'electricAuxiliaryHeat', type: 'electricAuxiliaryHeat', name: 'Electric Auxiliary Heat' },
  ];

  const data = {
    status: heatPumpStatuses[Math.round(Math.random() * 10) % heatPumpStatuses.length],
    components: heatPumpComponents,
  };

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ status: 200, data });
    }, Math.random() * 3000);
  });
};
export const deviceParameters = (deviceId) =>
  new Promise((resolve) => {
    let parameters = [];
    switch (deviceId) {
      case 'device1':
        parameters = [
          { id: 'power', name: 'Produced Power', unit: 'kW' },
          { id: 'energy', name: 'Produced Energy', unit: 'kWh' },
        ];
        break;
      case 'device2':
        parameters = [
          { id: 'temperature', name: 'Temperature', unit: '°C' },
          { id: 'humidity', name: 'Humidity', unit: '%' },
        ];
        break;
      case 'device3':
        parameters = [
          { id: 'pool_temperature', name: 'Pool Temperature', unit: '°C' },
          { id: 'air_temperature', name: 'Air Temperature', unit: '°C' },
        ];
        break;
      case 'device4':
        parameters = [
          { id: 'temperature', name: 'Temperature', unit: '°C' },
          { id: 'humidity', name: 'Humidity', unit: '%' },
        ];
        break;
    }
    resolve(parameters);
  });

export const getAllParameters = async (groupId) => {
  const res = await API.get(`${API_URL}/v2/group/${groupId}/categories/all`);

  const categories = {};
  res.data.parameters.forEach((p) => {
    if (categories[p.category]) {
      categories[p.category].push(p);
    } else {
      categories[p.category] = [p];
    }
  });

  return Object.keys(categories).map((c) => ({ id: c, name: c, parameters: categories[c] }));
};

export const getHistoryParameters = async (groupId) => {
  const res = await API.get(`${API_URL}/v2/group/${groupId}/categories/all?chart=history`);

  const categories = {};
  res.data.parameters.forEach((p) => {
    if (categories[p.category]) {
      categories[p.category].push(p);
    } else {
      categories[p.category] = [p];
    }
  });

  return Object.keys(categories).map((c) => ({ id: c, name: c, parameters: sortByPrio(categories[c]) }));
};

export const addDevice = async (serialNumber, token, groupName, groupId, address) => {
  return await API({
    method: 'post',
    url: `${API_URL}/v2/devices/me`,
    data: {
      serialNumber: serialNumber,
      token: token,
      name: groupName,
      groupId: groupId,
      address: address,
    },
  });
};

export const validateToken = async (serialNumber, token) => {
  const tokenResponse = await API({
    method: 'post',
    url: `${API_URL}/v2/devices/validateToken`,
    data: {
      serialNumber: serialNumber,
      token: token,
    },
  });
  return tokenResponse;
};

export const getYourTechnicianInfo = () =>
  new Promise((resolve) => {
    resolve({
      status: 200,
      data: {
        id: 'servicePartner',
        name: 'Your Technician Name',
        contactPhone: '556056-4485',
        contactEmail: 'yourtechnician@nibe.se',
        address: {
          city: 'Markaryd',
          country: 'Sweden',
          lineOne: '123 Some Road',
          lineTwo: 'Box 14',
          postalCode: '00-123',
        },
      },
    });
  });

export const getDeviceParameterValue = async (deviceId, parameterIds) => {
  try {
    if (!deviceId || parameterIds.length === 0) return { status: 200, data: [] };

    const response = await API.get(`${API_URL}/v2/devices/${deviceId}/points?parameters=${parameterIds.join(',')}`);

    const data = parameterIds.map((pId) => {
      const rd = response.data.find((r) => r.parameterId === pId);
      return (
        rd || {
          timestamp: '0000-00-00T09:00:00+00:00',
          value: null, // TODO: Demistify???
          rawValue: null,
          parameterId: pId,
        }
      );
    });

    return { status: 200, data };
  } catch (error) {
    const data = parameterIds.map((id) => ({
      timestamp: '0000-00-00T09:00:00+00:00',
      value: null,
      rawValue: null,
      parameterId: id,
    }));

    return { status: 200, data };
  }
};

export const getUserProfile = (id) => {
  return API.get(`${API_URL}/v2/users/${id}/profile`);
};

export const getTimeZones = () => {
  return API.get(`${API_URL}/v2/timezones`);
};

export const updateUserProfile = (id, profile) => {
  return API.put(`${API_URL}/v2/users/${id}/profile`, profile, CONFIG_JSON);
};

export const updateUserLanguage = (userInfo, language) => {
  const profile = JSON.parse(JSON.stringify(userInfo));
  profile.preferredLanguage = language;
  return API.put(`${API_URL}/v2/users/${userInfo.id}/profile`, profile, CONFIG_JSON);
};

export const changePassword = (id, oldPassword, newPassword) => {
  const body = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };

  return API.post(`${API_URL}/v2/users/${id}/password`, body, CONFIG_JSON);
};

export const changeEmail = (id, oldEmail, newEmail, password) => {
  const body = {
    userId: id,
    oldEmail: oldEmail,
    newEmail: newEmail,
    password: password,
  };

  return API.post(`${API_URL}/v2/users/${id}/email`, body, CONFIG_JSON);
};

export const getAddressInfo = (addressId) => {
  return API.get(`${API_URL}/v2/addresses/${addressId}`);
};

export const addAddressIdToUser = (userId, addressId) => {
  const body = {
    addressId: addressId,
  };

  return API.patch(`${API_URL}/v2/users/${userId}`, body, CONFIG_JSON);
};

export const changeAddressInfo = (address) => {
  return API.put(`${API_URL}/v2/addresses/user/${address.id}`, address, CONFIG_JSON);
};

export const changeSystemAddressInfo = (address, systemId) => {
  return API.put(`${API_URL}/v2/addresses/system/${address.id}/${systemId}`, address, CONFIG_JSON);
};

export const createSystemAddressInfo = (address, systemId) => {
  return API.post(`${API_URL}/v2/addresses/system/${systemId}`, address, CONFIG_JSON);
};

export const createAddressInfo = (address) => {
  return API.post(`${API_URL}/v2/addresses/user`, address, CONFIG_JSON);
};

export const getText = async (ids, langId) => {
  const responses = await Promise.all(ids.map((id) => API.get(`${API_URL}/v2/text/single/${id}/${langId}`)));
  return responses.filter((r) => r.status === 200).map((r) => r.data);
};

export const getGroupTileTypes = async (groupId) => {
  const response = await API.get(`${API_URL}/v2/groups/${groupId}/tiles`);
  return {
    ...response,
    data: [...response.data.map((t) => ({ ...t, id: t.id.replace('"', '').replace('"', '') }))],
  };
};

const getParameterTypes = (p) => {
  const types = ['setting'];
  if (p.parameter.presentation === 'display' || p.parameter.tileCollapsed) {
    types.push('display');
  }

  if (p.parameter.chart) {
    types.push('chart');
  }

  return types;
};

export const getTileParameters = async (groupId, tileId, tileNameTextId = '') => {
  const baseUrl = `${API_URL}/v2/groups/${groupId}/tiles/${tileId}`;
  const tileUrl = tileNameTextId ? `${baseUrl}?tileNameTextId=${tileNameTextId}` : baseUrl;

  const response = await API.get(tileUrl);

  return {
    ...response,
    data: response.data.map((p) => ({
      id: p.parameter.id,
      name: p.parameter.name,
      unit: p.parameter.unit,
      parameterTypes: getParameterTypes(p),
      icon: p.icon,
      text: p.text,
      deviceId: p.deviceId,
      parameter: p.parameter,
    })),
  };
};

export const patchTileSetting = async (deviceId, parameterId, value, unit) => {
  return await API.put(`${API_URL}/v2/devices/${deviceId}/points/${parameterId}`, {
    value: `${value}`,
    unit: unit,
  });
};

export const patchTileRawSetting = async (deviceId, parameterId, value, unit) => {
  return await API.put(`${API_URL}/v2/devices/${deviceId}/rawpoints/${parameterId}`, {
    value: `${value}`,
    unit: unit,
  });
};

export const patchMenuRawSetting = async (deviceId, menuId, parameterId, value, unit) => {
  return await API.put(`${API_URL}/v2/devices/${deviceId}/menu/${menuId}/rawpoints/${parameterId}`, {
    value: `${value}`,
    unit: unit,
  });
};

export const getAllBrands = async () => {
  return await API.get(`${API_URL}/v2/brands`);
};

export const getBrandDetails = async (brandId) => {
  return await API.get(`${API_URL}/v2/brands/${brandId}`);
};

export const sendContactEmail = (values) => {
  const body = {
    arguments: {
      fromName: `${values.fromName}`,
      fromEmail: `${values.fromEmail}`,
      subject: `${values.topic}`,
      body: `${values.message}`,
    },
  };
  return API.post(`${API_URL}/v2/Feedback?autoSend=true`, body, CONFIG_JSON);
};

export const createJiraServiceDeskSupportTicket = (values) => {
  let body = {
    raiseOnBehalfOf: `${values.fromEmail}`,
    requestTypeId: `${values.topic}`,
    platform: `${values.platform}`,
    summary: `${values.summary}`,
    description: `${values.message}`,
  };
  if (values.system) {
    body = { ...body, deviceId: values.system, brandId: values.brandId, description: `${values.message}` };
  }
  return API.post(`${API_URL}/create-support-ticket`, body, CONFIG_JSON);
};

export const findUserByEmail = async (anonymousToken, email) => {
  return await API.head(`${API_URL}/v2/users/search?email=${encodeURIComponent(email)}`, {
    ...CONFIG_JSON,
    headers: {
      ...CONFIG_JSON.headers,
      Authorization: `Bearer ${anonymousToken}`,
    },
  });
};

export const checkOldUserEmail = async (email) => {
  return await API.head(`${API_URL}/v2/old-users/${email}`);
};

export const getFirmwares = async (productId) => {
  return await API.get(`${API_URL}/v2/firmware/${productId}/all`);
};

export const getFirmwaresByBrand = async (brandId) => {
  return await API.get(`${API_URL}/v3/firmwareAlias/brands/${brandId.toUpperCase()}/all?latest=true`);
};

export const getMySystemsFirmwares = async () => {
  return await API.get(`${API_URL}/v3/firmwareAlias/users/me?latest=true`);
};

export const getActiveBrands = async () => {
  return await API.get(`${API_URL}/v2/firmware/brands/all`);
};

export const updateDeviceFirmware = async (deviceId, slaveDeviceId = '') => {
  return await API.post(`${API_URL}/v2/devices/${deviceId}/firmware/requestUpdate/${slaveDeviceId}`);
};

export const getInvitation = async (invitationId) => {
  return await API.get(`${API_URL}/v2/invitations/token/${invitationId}`);
};

export const getYourTechnician = (id) => {
  return API.get(`${API_URL}/v2/service-partner/${id}`);
};

export const acceptInvitation = ({ invitationId, groupIds, userId, servicePartnerId }) => {
  const body = {
    groupIds,
    servicePartnerId,
    userId,
  };

  return API.patch(`${API_URL}/v2/invitations/token/${invitationId}/accept`, body, CONFIG_JSON);
};

export const declineInvitation = (invitationId) => {
  const body = {};

  return API.patch(`${API_URL}/v2/invitations/token/${invitationId}/decline`, body, CONFIG_JSON);
};

export const getSystemUsers = async (systemId) => {
  return await API.get(`${API_URL}/v2/groups/${systemId}/security/users`);
};

export const getSystemRoles = async (systemId) => {
  return await API.get(`${API_URL}/v2/groups/${systemId}/security/roles`);
};

export const connectUser = async (systemId, userEmail, securityLevel) => {
  return await API.put(`${API_URL}/v2/groups/${systemId}/users/email/${userEmail}?role=${securityLevel}`);
};

export const disconnectUser = async (systemId, userId) => {
  return await API.delete(`${API_URL}/v2/groups/${systemId}/users/${userId}`);
};

export const disconnectYourTechnician = async (systemId, yourTechnicianId) => {
  return await API.delete(`${API_URL}/v2/groups/${systemId}/servicePartner/${yourTechnicianId}`);
};

export const disconnectHP24Account = async (systemId, accountId) => {
  return await API.delete(`${API_URL}/v2/groups/${systemId}/installer/${accountId}`);
};

export const changeUserSecurityLevel = async (systemId, userId, securityLevel) => {
  return await API.patch(`${API_URL}/v2/groups/${systemId}/users/${userId}?role=${securityLevel}`);
};

export const sendConfirmationDeleteUser = () => {
  return API.post(`${API_URL}/v2/users/send-confirmation-delete-user`);
};

export const confirmDeleteUser = async (token) => {
  return API.post(`${API_URL}/v2/users/confirm-delete-user`, { token });
};

export const deleteUserAccount = async (userId, password) => {
  return await API.delete(`${API_URL}/v2/users/${userId}`, { data: { password } });
};

export const confirmEmail = (token) => {
  const body = { token };
  return API.post(`${API_URL}/v2/users/confirm-email`, body, CONFIG_JSON);
};

export const sendConfirmationLink = (userId) => {
  const body = { userId };
  return API.post(`${API_URL}/v2/users/confirmation-link/send`, body, CONFIG_JSON);
};

export const getDeviceMenu = (deviceId, id) => {
  return API(`${API_URL}/v3/devices/${deviceId}/menu/${id}`);
};

export const invokeDeviceEvent = (deviceId, eventId) =>
  API.post(`${API_URL}/v2/devices/${deviceId}/invoke/triggerEvent/${eventId}`);

export const getSmartGuideQuestions = (deviceId, guideNumber) =>
  API(`${API_URL}/v2/devices/${deviceId}/invoke/getGuideQuestion/${guideNumber}`);

export const redeemVoucher = (systemId, voucherCode) => {
  const body = {
    groupId: systemId,
    voucherCode: voucherCode,
  };

  return API.post(`${API_URL}/v2/vouchers/redeem`, body, CONFIG_JSON);
};

export const confirmEmailUpdate = (token) => {
  const body = { token };
  return API.post(`${API_URL}/v2/users/email/confirm-update`, body, CONFIG_JSON);
};

export const getUserSubscriptionsFromEcom = (deviceId, brandId) =>
  API.get(`${API_URL}/v2/e-commerce/products/brands/${brandId}/devices/${deviceId}`);

export const getUserSubscriptions = (deviceId) => API.get(`${API_URL}/v2/subscriptions/device/${deviceId}`);

export const getDeviceAvailableFeatures = (deviceId) => API.get(`${API_URL}/v2/devices/${deviceId}/availableFeatures`);

export const getDeviceSubscriptionsInGroup = (groupId) => API.get(`${API_URL}/v2/subscriptions/${groupId}/devices`);

export const getCountriesList = () => API.get(`${API_URL}/v2/countries`);

export const getLatestAgreementsVersionsList = () => API.get(`${API_URL}/v2/agreements/latest`);

export const getUserAgreements = (userId) => API.get(`${API_URL}/v2/agreements/${userId}`);

export const acceptAgreement = (userId, agreement) => API.put(`${API_URL}/v2/agreements/${userId}`, agreement);

export const getCloudStatus = () => API.get(`${API_URL}/v2/status/consumer-web`);

export const API = axios.create({
  baseURL: API_URL,
  headers: {
    ...CONFIG_JSON.headers,
  },
  responseType: 'json',
});

export const getModes = async (deviceId) => {
  return await API.get(`${API_URL}/v2/devices/${deviceId}/schedule-modes`);
};

export const setModes = async (deviceId, newModes) => {
  return await API.put(`${API_URL}/v2/devices/${deviceId}/schedule-modes`, newModes);
};

export const deleteMode = async (deviceId, modeId) => {
  return await API.delete(`${API_URL}/v2/devices/${deviceId}/schedule-modes/${modeId}`);
};

export const getWeeklySchedules = async (deviceId) => {
  return await API.get(`${API_URL}/v2/devices/${deviceId}/weekly-schedules`);
};

export const setWeeklySchedules = async (deviceId, weeklySchedules) => {
  return await API.put(`${API_URL}/v2/devices/${deviceId}/weekly-schedules`, weeklySchedules);
};

export const getScheduleConfig = async (deviceId) => {
  return await API.get(`${API_URL}/v2/devices/${deviceId}/schedule-config`);
};

export const setVacationSchedules = async (deviceId, vacationSchedules) => {
  return await API.put(`${API_URL}/v2/devices/${deviceId}/vacation-schedules`, vacationSchedules);
};

export const getVacationSchedules = async (deviceId) => {
  return await API.get(`${API_URL}/v2/devices/${deviceId}/vacation-schedules`);
};

export const getScheduleModeStatus = async (deviceId) => {
  return await API.get(`${API_URL}/v2/devices/${deviceId}/schedule-status`);
};

export const setScheduleOverride = async (deviceId, scheduleOverride) => {
  return await API.put(`${API_URL}/v2/devices/${deviceId}/override-mode`, scheduleOverride);
};

export const getIotStoreRedirectUrl = async () => {
  return API.get(`${API_URL}/v2/e-commerce/iotStoreUrl`);
};

export const getAllLanguages = async () => API.get(`${API_URL}/v2/brands/supported-languages/all`);

export const getSupportedLanguages = async () => API.get(`${API_URL}/v2/brands/supported-languages/me`);

export const getDeviceUpdateStatus = async (deviceId) => {
  return await API.get(`${API_URL}/v2/devices/${deviceId}/firmware/status`);
};

export const updateSystemForEmailNotifications = async (groupId, value) => {
  return await API.patch(`${API_URL}/v2/groups/${groupId}/email-notification`, {
    notifyByEmail: `${value}`,
  });
};

export const updateSystemForPushNotifications = async (groupId, value) => {
  return await API.patch(`${API_URL}/v2/groups/${groupId}/push-notification`, {
    notifyByPush: `${value}`,
  });
};

export const getCollections = () => {
  return API.get(`${API_URL}/v2/e-commerce/collections`).then((res) => {
    return res.data;
  });
};

export const getCollection = (collectionId) => {
  return API.get(`${API_URL}/v2/e-commerce/collections/${collectionId}`).then((res) => {
    return res.data;
  });
};

export const getProducts = () => {
  return API.get(`${API_URL}/v2/e-commerce/products`).then((res) => {
    return res.data;
  });
};

export const getEComSupportedCountries = () => {
  return API.get(`${API_URL}/v2/e-commerce/countries`).then((res) => {
    return res.data;
  });
};

export const getProductsInCollection = (collectionHandle) => {
  return API.get(`${API_URL}/v2/e-commerce/collections/${collectionHandle}/products`).then((res) => {
    return res.data;
  });
};

export const getProduct = (productId) => {
  return API.get(`${API_URL}/v2/e-commerce/products/${productId}`).then((res) => {
    return res.data;
  });
};

export const getOrderHistory = () => {
  return API.get(`${API_URL}/v2/e-commerce/order-history`).then((res) => {
    return res.data;
  });
};

export const getSubscriptions = () => {
  return API.get(`${API_URL}/v2/e-commerce/subscriptions`).then((res) => {
    return res.data;
  });
};

export const initCart = (email, shippingAddress, customAttributes) => {
  /*
    Rewrite this to something reasonable, but it works for now
  */
  let firstName = 'defaultFirstname';
  let lastName = 'defaultlastName';
  if ((shippingAddress.name || '').indexOf(' ') !== -1) {
    firstName = shippingAddress.name.substr(0, shippingAddress.name.indexOf(' '));
    lastName = shippingAddress.name.substr(shippingAddress.name.indexOf(' ') + 1);
  } else if (shippingAddress.name) {
    firstName = shippingAddress.name;
  }

  const transformedAddress = {
    firstName: firstName,
    lastName: lastName,
    address1: shippingAddress.lineOne,
    address2: shippingAddress.lineTwo,
    city: shippingAddress.city,
    country: shippingAddress.country,
    province: shippingAddress.region,
    zip: shippingAddress.postalCode,
  };

  const body = {
    email: email,
    customAttributes: customAttributes,
    shippingAddress: transformedAddress,
  };

  return API.post(`${API_URL}/v3/e-commerce/consumer/checkout/create`, body).then((res) => {
    if (res.status !== 200) {
      return Promise.reject(res.data);
    }

    return {
      cart: res.data,
      shopId: res.headers.shopid,
    };
  });
};

export const getPaymentProviders = (cart, deviceId, activeTab = '') => {
  return API.get(
    `${API_URL}/v3/e-commerce/consumer/checkout/${cart.id}/paymentmethods/${deviceId}?activeTab=${activeTab}`
  )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      return Promise.reject(res);
    })
    .catch((err) => {
      NotificationManager.error(err.response.statusText);
      eventEmitter.emit(events.logError, err.toJSON());

      return Promise.reject(err.response.statusText);
    });
};

export const getUserPaymentProviderSettings = () => {
  return API.get('/v3/e-commerce/payment-provider-settings').then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject(res);
  });
};

export const updatePaymentProviderSettings = (paymentProviderSettings) => {
  return API.put(
    `/v2/e-commerce/users/paymentProviderSettings/${paymentProviderSettings.id}`,
    paymentProviderSettings
  ).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject(res);
  });
};

export const getOrderPaymentProviders = (orderId) => {
  return API.get(`${API_URL}/v2/e-commerce/orders/${orderId}/paymentmethods`).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return Promise.reject(res);
  });
};

export const getCart = (cartId) => {
  return API.get(`${API_URL}/v2/e-commerce/checkout/${cartId}`).then((res) => {
    return res.data;
  });
};

export const addProductToCart = (cart, product, variant) => {
  const body = {
    variantId: variant.id,
    quantity: 1,
  };

  return API.put(`${API_URL}/v2/e-commerce/checkout/${cart.id}/add`, body).then((res) => {
    return res.data;
  });
};

export const updateProductInCart = (cart, lineItem, quantity) => {
  const body = {
    quantity: quantity,
  };
  return API.put(`${API_URL}/v2/e-commerce/checkout/${cart.id}/${lineItem.id}`, body).then((res) => {
    return res.data;
  });
};

export const removeProductInCart = (cart, lineItem) => {
  const body = {
    quantity: 0,
  };
  return API.put(`${API_URL}/v2/e-commerce/checkout/${cart.id}/${lineItem.id}`, body).then((res) => {
    return res.data;
  });
};

export const createOrder = (cart, paymentProvider, paymentProviderData = {}) => {
  const body = {
    paymentProvider: paymentProvider,
    paymentProviderData: paymentProviderData,
  };

  return API.post(`${API_URL}/v2/e-commerce/checkout/${cart.id}/createOrder`, body).then((res) => {
    return res.data;
  });
};

export const getOrder = (orderId) => {
  return API.get(`${API_URL}/v2/e-commerce/orders/${orderId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return Promise.reject(err.response.statusText);
      }

      return Promise.reject(err);
    });
};

export const payPendingOrder = async (orderId) => {
  return await API.post(`${API_URL}/v2/e-commerce/orders/${orderId}/pay`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        return Promise.reject(err.response.statusText);
      }
    });
};

export const cancelOrder = (orderId) => {
  return API.put(`${API_URL}/v2/e-commerce/orders/${orderId}/cancel`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return Promise.reject(err.response.statusText);
      }

      return Promise.reject(err);
    });
};

export const cancelSubscription = (id) => {
  return API.put(`/v3/user/subscriptions/${id}/cancel`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return Promise.reject(err.response.statusText);
      }
      return Promise.reject(err);
    });
};

export const resumeSubscription = (id) => {
  return API.put(`/v3/user/subscriptions/${id}/resume`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response && err.response.status === 404) {
        return Promise.reject(err.response.statusText);
      }
      return Promise.reject(err);
    });
};

export const getThirdPartyApps = async (userId) => {
  return await API.get(`${API_URL}/v2/grants/${userId}/3rd-party`);
};

export const removeThirdPartyAppAccess = async (userId, clientId) => {
  return await API.delete(`${API_URL}/v2/grants/${userId}/${clientId}`);
};

export const getFaqQuestions = async (brandId, lastUsedLanguage) => {
  return await API.get(`${API_URL}/v2/faq?brand=${brandId}&locale=${lastUsedLanguage}&tag=consumer`);
};

export const getFaqItems = async (brandId, lastUsedLanguage) => {
  return await API.get(`${API_URL}/v2/faq/all?brand=${brandId}&locale=${lastUsedLanguage}&tag=consumer`);
};

export const getWeatherTileData = async (groupId) => {
  return await API.get(`${API_URL}/v2/groups/${groupId}/weather-tile-data`);
};

export const getYourTechniciansList = (brandId = 'Nibe', pageNumber = 1, pageSize = 10) => {
  return API.get(`${API_URL}/v2/service-partners/${brandId}/active?pageNumber=${pageNumber}&pageSize=${pageSize}`);
};

export const getYourTechnicianByNameHint = (nameHint, brandId = 'Nibe') => {
  return API.get(`${API_URL}/v2/service-partners/${brandId}/active/name/${nameHint}`);
};

export const getPaidBrands = () => {
  return API.get('/v2/e-commerce/brands/consumer/paid')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      NotificationManager.error(err.response.statusText);
      eventEmitter.emit(events.logError, err.toJSON());

      return Promise.reject(err.response.statusText);
    });
};

export const activateTrialExists = async (brandId, deviceId, body) => {
  return await API.post(`/v2/existing-subscriptions/device/${deviceId}/brand/${brandId}/activate-trial`, body);
};
